import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Mark from "mark.js";

// core components
import Button from "components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import InputAdornment from "@material-ui/core/InputAdornment";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CustomInput from "components/CustomInput/CustomInput.js";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NestedMenuItem from "material-ui-nested-menu-item";
import Backdrop from "@material-ui/core/Backdrop";

// material ui icon
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

//dock
import DockLayout from 'rc-dock';
import "rc-dock/dist/rc-dock.css";
import '../../../assets/css/codeSection.css';
//markdown
import ReactMarkdown from 'react-markdown'
// editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
// audio player
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import SearchIcon from '@material-ui/icons/Search';
import Close from "@material-ui/icons/Close";
import ErrorIcon from '@material-ui/icons/Error';
import SaveIcon from '@material-ui/icons/Save';
import SettingsIcon from '@material-ui/icons/Settings';

// Modal
import Slide from "@material-ui/core/Slide";
import Modal from 'react-bootstrap/Modal';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import '../../../assets/css/modal.css';

import { decode } from 'html-entities';

import styles from "assets/jss/material-kit-react/views/tutorialPageSections/codeStyle";

import Highlighter from "react-highlight-words";

import TranscriptEditor from "../TranscriptEditor.js";

import { useIdleTimer } from 'react-idle-timer';

import { useBeforeunload } from 'react-beforeunload';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: "100%" }}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(styles);
const Context = React.createContext();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CodeSection(props) {
  const { id } = useParams();
  const classes = useStyles();
  const JSZip = require('jszip');

  const transcriptEditorRef = useRef(null);

  const [playbackEvents, setPlaybackEvents] = useState([]);

  const [currentTime, setCurrentTime] = useState(0);
  const [currentKeystroke, setCurrentKeystroke] = useState(0);
  const [currentConsoleAction, setCurrentConsoleAction] = useState(0);
  const [currentConsoleScrollAction, setCurrentConsoleScrollAction] = useState(0);
  const [currentInputKeystroke, setCurrentInputKeystroke] = useState(0);
  const [currentInputScrollAction, setCurrentInputScrollAction] = useState(0);
  const [currentLayoutAction, setCurrentLayoutAction] = useState(0);
  const [currentSelectAction, setCurrentSelectAction] = useState(0);
  const [currentScrollAction, setCurrentScrollAction] = useState(0);
  const [currentEditorScrollAction, setCurrentEditorScrollAction] = useState(0);
  const [currentTranscript, setCurrentTranscript] = useState(0);
  const [currentTranscriptText, setCurrentTranscriptText] = useState("");
  const [playState, setPlayState] = useState(false);
  const [startState, setStartState] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [stackoverflowSearchField, setStackoverflowSearchField] = useState("");
  const [consoleError, setConsoleError] = useState("");
  const [stackoverflowLoading, setStackoverflowLoading] = useState(false);
  const [frequentWord, setFrequentWord] = useState([]);
  const [stackoverflowSuggestedResult, setStackoverflowSuggestedResult] = useState([]);
  const [stackoverflowResult, setStackoverflowResult] = useState();
  const [audioSearchResult, setAudioSearchResult] = useState(null);
  const [audioSearchLoading, setAudioSearchLoading] = useState(false);
  const [descriptionSearchResult, setDescriptionSearchResult] = useState(null);
  const [descriptionInnerText, setDescriptionInnerText] = useState("");
  const [descriptionSearchOccurence, setDescriptionSearchOccurence] = useState([]);
  const [showClearSearch, setShowClearSearch] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [keystrokes, setKeystrokes] = useState([]);
  const [consoleActions, setConsoleActions] = useState([]);
  const [consoleScrollActions, setConsoleScrollActions] = useState([]);
  const [inputKeystrokes, setInputKeystrokes] = useState([]);
  const [inputScrollActions, setInputScrollActions] = useState([]);
  const [selectActions, setSelectActions] = useState([]);
  const [scrollActions, setScrollActions] = useState([]);
  const [editorScrollActions, setEditorScrollActions] = useState([]);
  const [mediaActions, setMediaActions] = useState([]);
  const [accessedLinks, setAccessedLinks] = useState([]);
  const [zip, setZip] = useState(new JSZip());
  const [file, setFile] = useState(null);

  const [showCodeRun, setShowCodeRun] = useState(true);
  const [ide, setIde] = useState("");
  const [playbackCursor, setPlaybackCursor] = useState([1, 1]);
  const [practiceCursor, setPracticeCursor] = useState([1, 1]);
  const [random, setRandom] = useState(0);

  const [playbackSettingsAnchor, setPlaybackSettingsAnchor] = useState(null);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const [isPlaybackBarVisible, setIsPlaybackBarVisible] = useState(true);
  const [updateComplete, setUpdateComplete] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const timeout = 1000 * 60 * 10   // 10 min before idle timeout
  const [isIdle, setIsIdle] = useState(false);
  const [timer, setTimer] = useState(5);

  const countdown = useRef(null);
  const startTimeRef = useRef(null);
  const fileRef = useRef(null);


  //TODO: For multi-files in the future can call this method to update the practiceCodes dynamically
  const updatePracticeCode = (filename, codeContent) => {
    props.practiceCode[filename] = codeContent
  }


  const tutorTab = () => {
    // Python Tutor tab contents
    var id, title, curInstr, language;
    if (props.languageChosen == "python") {
      id = "python-tutor";
      title = "Python Tutor";
      curInstr = "1";
      language = "3";
      return {
        id: id,
        title: title,
        cached: true,
        closable: true,
        content: (
          <Context.Consumer>
            {(value) =>
              <div className={classes.editorPane}>
                {value.ide ?
                  <iframe width="100%" height="100%" frameBorder="0" key={value.random}
                    src={"https://pythontutor.com/iframe-embed.html#code=" + encodeURIComponent(value.ide) + "&cumulative=false&curInstr=" + curInstr + "&heapPrimitives=nevernest&origin=opt-frontend.js&py=" + language + "&rawInputLstJSON=%5B%5D&textReferences=false"}>
                  </iframe> :
                  <p>No code in Editor panel</p>
                }
              </div>
            }
          </Context.Consumer>
        )
      }
    } else if (props.languageChosen == "java") {
      id = "java-tutor";
      title = "Java Tutor";
      curInstr = "0";
      language = "java";
      return {
        id: id,
        title: title,
        cached: true,
        closable: true,
        content: (
          <Context.Consumer>
            {(value) =>
              <div className={classes.editorPane}>
                {value.ide ?
                  <a key={value.random} target="_blank"
                    href={"http://pythontutor.com/iframe-embed.html#code=" + encodeURIComponent(value.ide) + "&cumulative=false&curInstr=" + curInstr + "&heapPrimitives=nevernest&origin=opt-frontend.js&py=" + language + "&rawInputLstJSON=%5B%5D&textReferences=false"}>
                    Link to Java Tutor
                  </a> :
                  <p>No code in Editor panel</p>
                }
              </div>
            }
          </Context.Consumer>
        )
      }
    } else if (props.languageChosen == "javascript") {
      id = "javascript-tutor";
      title = "JavaScript Tutor";
      curInstr = "0";
      language = "js";
      return {
        id: id,
        title: title,
        cached: true,
        closable: true,
        content: (
          <Context.Consumer>
            {(value) =>
              <div className={classes.editorPane}>
                {value.ide ?
                  <a key={value.random} target="_blank"
                    href={"http://pythontutor.com/iframe-embed.html#code=" + encodeURIComponent(value.ide) + "&cumulative=false&curInstr=" + curInstr + "&heapPrimitives=nevernest&origin=opt-frontend.js&py=" + language + "&rawInputLstJSON=%5B%5D&textReferences=false"}>
                    Link to Java Tutor
                  </a> :
                  <p>No code in Editor panel</p>
                }
              </div>
            }
          </Context.Consumer>
        )
      }
    }
  }

  const practiceTab = () => {
    return {
      id: 'practice', title: 'Practice', cached: true, closable: true, content: (
        <div className={classes.editorPane}>
          <AceEditor
            className={classes.editor}
            mode={props.languageChosen}
            theme={props.themeChosen}
            name="practiceIde"
            ref={props.practiceIdeRef}
            editorProps={{ $blockScrolling: true }}
            onCursorChange={(e) => setPracticeCursor([e.cursor.row + 1, e.cursor.column + 1])}
            setOptions={{ enableLiveAutocompletion: true, }}
            onChange={(codeContent) => {
              props.setIdeChanged(true);
              updatePracticeCode("Practice", codeContent);
            }}
            value={props.practiceCode.Practice}
          />
          <Context.Consumer>
            {(value) => <div className={classes.bottomLeftDiv}>
              Ln {value.practiceCursor[0]}, Col {value.practiceCursor[1]} | Spaces: 4 | Filename: {value.filename}
            </div>}
          </Context.Consumer>
          <Context.Consumer>
            {(value) => value.showCodeRun ?
              <Button size="sm" round color="secondary" className={classes.testButton} onClick={() => handleTestRun()}>
                <PlayArrowIcon /> Run Test
              </Button> :
              <CircularProgress className={classes.spinner} />
            }
          </Context.Consumer>
          <Context.Consumer>
            {(value) => value.showCodeRun ?
              <Button size="sm" round color="success" className={classes.codeButton} onClick={() => handlePracticeRun()}>
                <PlayArrowIcon /> Run Code
              </Button> :
              <CircularProgress className={classes.spinner} />
            }
          </Context.Consumer>
        </div>
      )
    }
  }

  const defaultLayout = {
    dockbox: {
      mode: 'horizontal',
      children: [
        {
          size: 400,
          tabs: [
            {
              id: 'description-preview', title: 'Description', closable: true, content: (
                <div id="markdownDiv" className={classes.markdownDiv}>
                  <Context.Consumer>
                    {(value) =>
                      <ReactMarkdown className={classes.markdownResult}>{value.description}</ReactMarkdown>
                    }
                  </Context.Consumer>
                  <Context.Consumer>
                    {(value) => value.showClearSearch && <div className={classes.clearSearchDiv} onClick={() => handleClearDescriptionSearch()}>
                      <Close />Clear Search
                    </div>}
                  </Context.Consumer>
                </div>
              )
            },
            { id: 'default' },
          ]
        },
        {
          mode: 'vertical',
          size: 600,
          children: [
            {
              id: 'practiceBoxData',
              size: 600,
              tabs: [
                {
                  id: 'editor', title: 'Playback', cached: true, closable: true, content: (
                    <div className={classes.editorPane}>
                      <AceEditor
                        className={classes.editor}
                        mode={props.languageChosen}
                        theme={props.themeChosen}
                        name="playbackIde"
                        ref={props.playbackIdeRef}
                        editorProps={{ $blockScrolling: true }}
                        onChange={(e) => setIde(e)}
                        onCursorChange={(e) => setPlaybackCursor([e.cursor.row + 1, e.cursor.column + 1])}
                        setOptions={{ enableLiveAutocompletion: true, }}
                      />
                      <Context.Consumer>
                        {(value) => <div className={classes.bottomLeftDiv}>
                          Ln {value.playbackCursor[0]}, Col {value.playbackCursor[1]} | Spaces: 4 | Filename: {value.filename}
                        </div>}
                      </Context.Consumer>
                      <Context.Consumer>
                        {(value) => value.showCodeRun ?
                          <Button size="sm" id="playback-code-run-button" round color="success" className={classes.codeButton} onClick={() => { handlePlaybackRun(); setRandom(value.random + 1) }}>
                            <PlayArrowIcon /> Run Code
                          </Button> :
                          <CircularProgress className={classes.spinner} />
                        }
                      </Context.Consumer>
                    </div>
                  )
                },
                practiceTab()
              ],
              panelLock: {
                panelExtra: (panelData, context) => (
                  <>
                    <span className='dock-panel-max-btn'
                      onClick={() => context.dockMove(panelData, null, 'maximize')}>
                    </span>

                  </>
                )

              }
            },
            {
              mode: 'horizontal',
              size: 400,
              children: [
                {
                  size: 100,
                  tabs: [
                    {
                      id: 'input', title: 'Input', cached: true, closable: true, content: (
                        <AceEditor
                          className={classes.editor}
                          mode="sh"
                          theme={props.themeChosen}
                          name="input"
                          ref={props.inputIdeRef}
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          showPrintMargin={false}
                          setOptions={{
                            showLineNumbers: false
                          }}
                        />
                      )
                    }
                  ]
                },
                {
                  tabs: [
                    {
                      id: 'output', title: 'Console', cached: true, closable: true, content: (
                        <div className={classes.editorPane}>
                          <AceEditor
                            className={classes.editor}
                            mode="sh"
                            theme={props.themeChosen}
                            name="console"
                            ref={props.consoleIdeRef}
                            editorProps={{ $blockScrolling: true }}
                            highlightActiveLine={false}
                            showGutter={false}
                            readOnly={true}
                            showPrintMargin={false}
                            setOptions={{
                              showLineNumbers: false
                            }}
                          />
                          <div className={classes.bottomLeftDiv}>
                            {props.version}
                          </div>
                          <div className={classes.bottomRightDiv}>
                            <Context.Consumer>
                              {(value) => value.consoleError != "" &&
                                <IconButton onClick={() => { props.setShowSearchModal(true); props.setTabValue(1) }}>
                                  <ErrorIcon color="error" />
                                </IconButton>
                              }
                            </Context.Consumer>
                            <Button size="sm" round onClick={() => handleClearConsole()}>
                              Clear Output
                            </Button>
                          </div>
                        </div>
                      )
                    },
                    tutorTab(),
                  ]
                }
              ]
            }
          ],

        }
      ]
    }
  };


  useEffect(() => {
    if (props.showSearchModal == true) {
      setStackoverflowSuggestedResult([])
      if (consoleError) {
        setStackoverflowLoading(true);
        const requestOptions = {
          method: 'GET'
        }
        fetch("https://api.stackexchange.com/2.3/search/advanced?page=1&pagesize=5&order=desc&sort=relevance&answers=1&tagged=" + props.languageChosen + "&body=" + consoleError + "&site=stackoverflow&key=" + process.env.REACT_APP_STACKEXCHANGE_KEY + "&filter=withbody", requestOptions)
          .then(response => response.json())
          .then(data => {
            setStackoverflowLoading(false);
            console.log(data.items[0])
            setStackoverflowSuggestedResult(data.items);
          })
      }
    }
  }, [props.showSearchModal])

  const recordAccessedLink = (link) => {
    if (!accessedLinks.includes(link)) {
      accessedLinks.push(link);
    }
    console.log(accessedLinks);
  }

  useEffect(() => {
    setFrequentWord(props.frequentWord)
  }, [props.frequentWord])

  useEffect(() => {
    var inputRefObserver = new MutationObserver(function (mutations, me) {
      if (props.inputIdeRef) {
        props.inputIdeRef.current.editor.setValue(props.input);
        props.inputIdeRef.current.editor.clearSelection();
        me.disconnect();
        return;
      }
    })
    inputRefObserver.observe(document, {
      childList: true,
      subtree: true
    });
  }, [props.input])

  useEffect(() => {
    var descriptionDiv = document.getElementById("description-preview")
    setDescriptionInnerText(descriptionDiv.innerText.split('\n').filter((elem) => elem != ""))
  }, [props.description])



  const handlePlaybackRun = () => {
    // Playback tab code run function
    if (!props.consoleIdeRef.current) {
      return
    }
    var input = "";
    if (props.inputIdeRef.current) {
      input = props.inputIdeRef.current.editor.getValue();
    }
    var ide = props.playbackIdeRef.current.editor.getValue();
    setShowCodeRun(false);
    setRandom(random + 1);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ data: ide, input: input, filename: props.filename })
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + 'run_script/' + props.languageChosen, requestOptions)
      .then(response => response.json())
      .then(data => {
        var newResult = props.consoleIdeRef.current.editor.getValue() + "> " + data.output;
        if (data.time) {
          newResult += "> Time taken: " + (Math.ceil(data.time * 1000) / 1000).toFixed(3) + "s\n";
        }
        props.consoleIdeRef.current.editor.setValue(newResult);
        props.consoleIdeRef.current.editor.clearSelection();
        props.consoleIdeRef.current.editor.session.setScrollTop(99999999);
        const output = data.output.toLowerCase();

        if (output.includes("error") || output.includes("exception")) {
          const sentenceArray = output.split("\n")
          for (const sentence of sentenceArray) {
            if (sentence.includes("error") || sentence.includes("exception")) {
              var consoleErrorString = ""
              for (const word of sentence.split(" ")) {
                if (props.languageChosen == "python") {
                  if (!word.includes("'")) {
                    consoleErrorString += word + " ";
                  }
                }
                if (props.languageChosen == "java" || props.languageChosen == "javascript") {
                  if (word.includes("error") || word.includes("exception")) {
                    consoleErrorString += word + " ";
                  }
                }
              }
              setConsoleError(consoleErrorString);
            }
          }
        } else {
          setConsoleError("");
        }

        setShowCodeRun(true);
      });
  }

  const handlePracticeRun = () => {
    // Practice tab code run function
    if (!props.consoleIdeRef.current) {
      return
    }
    var input = "";
    if (props.inputIdeRef.current) {
      input = props.inputIdeRef.current.editor.getValue();
    }
    var ide = props.practiceIdeRef.current.editor.getValue();
    setShowCodeRun(false);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ data: ide, input: input, filename: props.filename })
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + 'run_script/' + props.languageChosen, requestOptions)
      .then(response => response.json())
      .then(data => {
        recordConsoleAction(data.output)
        var newResult = props.consoleIdeRef.current.editor.getValue() + "> " + data.output;
        if (data.time) {
          newResult += "> Time taken: " + (Math.ceil(data.time * 1000) / 1000).toFixed(3) + "s\n";
        }
        props.consoleIdeRef.current.editor.setValue(newResult);
        props.consoleIdeRef.current.editor.clearSelection();
        props.consoleIdeRef.current.editor.session.setScrollTop(999999999);
        setShowCodeRun(true);

        const output = data.output.toLowerCase();
        if (output.includes("error") || output.includes("exception")) {
          const sentenceArray = output.split("\n")
          for (const sentence of sentenceArray) {
            if (sentence.includes("error") || sentence.includes("exception")) {
              var consoleErrorString = ""
              for (const word of sentence.split(" ")) {
                if (props.languageChosen == "python") {
                  if (!word.includes("'")) {
                    consoleErrorString += word + " ";
                  }
                }
                if (props.languageChosen == "java" || props.languageChosen == "javascript") {
                  if (word.includes("error") || word.includes("exception")) {
                    consoleErrorString += word + " ";
                  }
                }
              }
              setConsoleError(consoleErrorString);
            }
          }
        } else {
          setConsoleError("");
        }
      });
  }


  const handleTestRun = () => {
    // Practice tab code run function
    if (!props.consoleIdeRef.current) {
      return
    }
    var ide = props.practiceIdeRef.current.editor.getValue();
    setShowCodeRun(false);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ data: ide, tutorial_section_id: props.tutorialSectionId, filename: props.filename })
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + `/${localStorage.getItem("session_id")}/` + 'run_test/' + props.languageChosen, requestOptions)
      .then(response => response.json())
      .then(data => {
        var newResult = props.consoleIdeRef.current.editor.getValue()
        Object.keys(data).forEach(testNumber => {
          newResult += `>${testNumber}: ${data[testNumber].result}`
          if (data[testNumber].time) {
            newResult += `, time taken: ${(Math.ceil(data[testNumber].time * 1000) / 1000).toFixed(3)}s`
          }
          newResult += "\n"
        });
        props.consoleIdeRef.current.editor.setValue(newResult);
        props.consoleIdeRef.current.editor.clearSelection();
        props.consoleIdeRef.current.editor.session.setScrollTop(999999999);
        setShowCodeRun(true);
      });
  }

  const removeTagsAndCodes = (text) => {
    // remove the html tags and code tag from markdown description
    const strippedText = decode(text.replace(/<(.|\n)*?>/g, '').replace(/\n/g, ' '));
    const strippedArray = strippedText.split(" ");
    if (strippedArray.length > 30) {
      return strippedArray.slice(0, 30).join(" ") + "...";
    }
    return strippedText;
  }

  const handleClearConsole = () => {
    props.consoleIdeRef.current.editor.setValue("");
  }

  function play() {
    // Function when Media Player play button is clicked
    setPlayState(true);
    if (!startState) {
      setStartState(true);
      setStartTime(Date.now());
      setConsoleActions([]);

      if (props.practiceIdeRef.current) {
        const stateKeyEvent = {
          'content': props.practiceIdeRef.current.editor.getValue(),
          'selection': props.practiceIdeRef.current.editor.getSelectionRange(),
          'timestamp': 0
        }
        setKeystrokes([stateKeyEvent]);

        const stateEditorScrollEvent = {
          'timestamp': 0,
          'scroll': props.practiceIdeRef.current.editor.session.getScrollTop(),
        }
        setEditorScrollActions([stateEditorScrollEvent])
      }

      if (props.inputIdeRef.current) {
        const stateKeyEvent = {
          'content': props.inputIdeRef.current.editor.getValue(),
          'selection': props.inputIdeRef.current.editor.getSelectionRange(),
          'timestamp': 0
        }
        setInputKeystrokes([stateKeyEvent])

        const stateInputScrollEvent = {
          'timestamp': 0,
          'scroll': props.inputIdeRef.current.editor.session.getScrollTop(),
        }
        setInputScrollActions([stateInputScrollEvent]);
      }

      const stateScrollEvent = {
        'timestamp': 0,
        'scroll': getMarkdownScroll(),
      }
      setScrollActions([stateScrollEvent]);

      const stateSelectEvent = {
        'timestamp': 0,
        'data': getMarkdownSelect(),
      }
      setSelectActions([stateSelectEvent]);

      const mediaEvent = {
        'timestamp': 0,
        'action': 'start',
        'speed': playbackSpeed,
        'time': Math.round(props.player.current.audio.current.currentTime * 1000)
      }
      setMediaActions([mediaEvent]);
    }

    if (props.playbackIdeRef.current) {
      props.playbackIdeRef.current.editor.clearSelection();
    }
    for (var i = currentKeystroke; i < props.keystrokes.length; i++) {
      createIdeEvent(i, currentTime);
    }
    for (var i = currentConsoleAction; i < props.consoleActions.length; i++) {
      createConsoleEvent(i, currentTime);
    }
    for (var i = currentConsoleScrollAction; i < props.consoleScrollActions.length; i++) {
      createConsoleScrollEvent(i, currentTime);
    }
    if (props.inputKeystrokes != null) {
      for (var i = currentInputKeystroke; i < props.inputKeystrokes.length; i++) {
        createInputEvent(i, currentTime);
      }
    }
    for (var i = currentInputScrollAction; i < props.inputScrollActions.length; i++) {
      createInputScrollEvent(i, currentTime);
    }
    for (var i = currentLayoutAction; i < props.layoutActions.length; i++) {
      createLayoutEvent(i, currentTime);
    }
    for (var i = currentSelectAction; i < props.selectActions.length; i++) {
      createMarkdownSelectEvent(i, currentTime);
    }
    for (var i = currentScrollAction; i < props.scrollActions.length; i++) {
      createMarkdownScrollEvent(i, currentTime);
    }
    for (var i = currentEditorScrollAction; i < props.editorScrollActions.length; i++) {
      createEditorScrollEvent(i, currentTime);
    }
    if (props.transcript) {
      for (var i = currentTranscript; i < props.transcript.length; i++) {
        createTranscriptEvent(i, currentTime);
      }
    }
    if (!props.playbackBarAlwaysVisible) {
      setTimeout(() => {
        setIsPlaybackBarVisible(false)
      }, 300)
    }
  }

  function createIdeEvent(i, time) {
    // Handles code and selection playback in the Playback tab
    var k = props.keystrokes[i];

    var evt = setTimeout(() => {
      setCurrentKeystroke(i);
      if (props.playbackIdeRef.current) {
        props.playbackIdeRef.current.editor.clearSelection();

        if (k.content) {
          props.playbackIdeRef.current.editor.setValue(k.content, 1);
        } else {
          props.playbackIdeRef.current.editor.setValue("", 1);
        }

        props.playbackIdeRef.current.editor.selection.setRange(k.selection);
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt);
  }

  function createConsoleEvent(i, time) {
    // Handles code and selection playback in the Console tab
    var k = props.consoleActions[i];

    var evt = setTimeout(() => {
      setCurrentConsoleAction(i);
      if (props.consoleIdeRef.current) {
        props.consoleIdeRef.current.editor.clearSelection();

        if (k.content) {
          props.consoleIdeRef.current.editor.setValue(k.content, 1);
        } else {
          props.consoleIdeRef.current.editor.setValue("", 1);
        }

        props.consoleIdeRef.current.editor.selection.setRange(k.selection);
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt);
  }

  function createConsoleScrollEvent(i, time) {
    // Handles vertical scroll in console tab
    var k = props.consoleScrollActions[i];

    var evt = setTimeout(() => {
      if (props.consoleIdeRef.current) {
        props.consoleIdeRef.current.editor.session.setScrollTop(k.scroll);
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt)
  }

  function createInputEvent(i, time) {
    // Handles code and selection playback in the input tab
    var k = props.inputKeystrokes[i];

    var evt = setTimeout(() => {
      setCurrentInputKeystroke(i);
      if (props.inputIdeRef.current) {
        props.inputIdeRef.current.editor.clearSelection();

        if (k.content) {
          props.inputIdeRef.current.editor.setValue(k.content, 1);
        } else {
          props.inputIdeRef.current.editor.setValue("", 1);
        }

        props.inputIdeRef.current.editor.selection.setRange(k.selection);
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt);
  }

  function createInputScrollEvent(i, time) {
    // Handles vertical scroll playback in input tab
    var k = props.inputScrollActions[i];

    var evt = setTimeout(() => {
      if (props.inputIdeRef.current) {
        props.inputIdeRef.current.editor.session.setScrollTop(k.scroll);
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt)
  }

  function createLayoutEvent(i, time) {
    // Handles playback layout changes
    var k = props.layoutActions[i];

    var evt = setTimeout(() => {
      props.layoutRef.current.loadLayout(props.layoutActions[i].layout);
      props.layoutRef.current.dockMove(practiceTab(), 'editor', "after-tab")
      props.layoutRef.current.dockMove(props.layoutRef.current.find('editor'), 'practice', "before-tab")
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt);
  }

  function createMarkdownScrollEvent(i, time) {
    // Handles vertical scroll in Description tab
    var k = props.scrollActions[i];

    var evt = setTimeout(() => {
      var markdownDivElement = document.getElementById('markdownDiv');
      if (markdownDivElement) {
        var scrollPercent = k.scroll;
        var scrollHeight = markdownDivElement.scrollHeight - markdownDivElement.clientHeight;
        var scrollPosition = scrollPercent * scrollHeight;
        markdownDivElement.scrollTop = scrollPosition;
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt);
  }

  function createEditorScrollEvent(i, time) {
    // Handles vertical scroll in editor 
    var k = props.editorScrollActions[i];

    var evt = setTimeout(() => {
      if (props.playbackIdeRef.current) {
        props.playbackIdeRef.current.editor.session.setScrollTop(k.scroll);
      }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt)
  }

  function createMarkdownSelectEvent(i, time) {
    // Handles selection in Description tab
    var k = props.selectActions[i];
    var evt = setTimeout(() => {
      try {
        use(JSON.parse(k.data));
      } catch (e) { }
    }, (k.timestamp - time) / playbackSpeed);

    playbackEvents.push(evt);
  }

  function createTranscriptEvent(i, time) {
    var k = props.transcript[i];
    var evt = setTimeout(() => {
      setCurrentTranscriptText(k.text)
    }, (k.timestamp - time) / playbackSpeed);
    playbackEvents.push(evt)

    if (!playState) {
      var evt2 = setTimeout(() => {
        setCurrentTranscriptText("")
      }, (k.endTime - time) / playbackSpeed);
      playbackEvents.push(evt2)
    }
  }

  function findEle(tagName, innerHTML) {
    // returns DOM element of matching tag name and inner HTML
    let list = document.getElementsByTagName(tagName);
    for (let i = 0; i < list.length; i++) {
      if (list[i].innerHTML == innerHTML) {
        return list[i];
      }
    }
  }

  function show(startNode, startIsText, startOffset, endNode, endIsText, endOffset, sP, eP) {
    // select element with window.getSelection
    var s, e;
    if (startIsText) {
      let childs = sP.childNodes;
      for (let i = 0; i < childs.length; i++) {
        if (childs[i].nodeType == 3 && childs[i].nodeValue == startNode)
          s = childs[i];
      }
    } else {
      s = startNode;
    }
    if (endIsText) {
      let childs = eP.childNodes;
      for (let i = 0; i < childs.length; i++) {
        if (childs[i].nodeType == 3 && childs[i].nodeValue == endNode)
          e = childs[i];
      }
    } else {
      e = startNode;
    }
    let range = document.createRange();
    range.setStart(s, startOffset);
    range.setEnd(e, endOffset);

    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  function use(obj) {
    let sP = findEle(obj.startTagName, obj.startHTML);
    let eP = findEle(obj.endTagName, obj.endHTML);
    show(
      obj.startNode,
      obj.startIsText,
      obj.startOffset,
      obj.endNode,
      obj.endIsText,
      obj.endOffset,
      sP,
      eP
    );
  }

  function pause() {
    setPlayState(false);
    for (var i = 0; i < playbackEvents.length; i++) {
      clearTimeout(playbackEvents[i]);
    }
    setPlaybackEvents([]);
    var time = props.player.current.audio.current.currentTime * 1000;
    setCurrentTime(time);

    setIsPlaybackBarVisible(true);

    return Promise.resolve(time);
  }

  function seek() {
    setPlayState(false);
    for (var i = 0; i < playbackEvents.length; i++) {
      clearTimeout(playbackEvents[i]);
    }
    setPlaybackEvents([]);
    var time = props.player.current.audio.current.currentTime * 1000;
    setCurrentTime(time);

    setIsPlaybackBarVisible(true);

    return Promise.resolve(time);
  }

  function getCurrentElement(elementArray, time) {
    // return index of elementArray which would be run at given time (it's a binary search) 
    var bottom = 0;
    var top = elementArray.length - 1;
    var mid = 0;
    while (bottom <= top) {
      mid = Math.floor((bottom + top) / 2);
      if (time < elementArray[mid].timestamp) {
        if (mid == 0) {
          return -1;
        } else {
          top = mid - 1;
        }
      } else {
        if (mid == elementArray.length - 1) {
          break;
        } else if (time >= elementArray[mid + 1].timestamp) {
          bottom = mid + 1;
        } else {
          break;
        }
      }
    }
    return mid;
  }

  function seeked() {
    // Set respective states of playback when Media Player is seeked
    // Continue to play from seeked if Media Player was playing when seeked
    var currentPlayState = playState;

    props.player.current.audio.current.pause();

    seek()
      .then((time) => {
        var keystroke = getCurrentElement(props.keystrokes, time);
        setCurrentKeystroke(keystroke);
        createIdeEvent(keystroke, time);
        var consoleAction = getCurrentElement(props.consoleActions, time);
        setCurrentConsoleAction(consoleAction);
        createConsoleEvent(consoleAction, time);
        var consoleScrollAction = getCurrentElement(props.consoleScrollActions, time);
        setCurrentConsoleScrollAction(consoleScrollAction);
        createConsoleScrollEvent(consoleScrollAction, time);

        if (props.inputKeystrokes != null) {
          var inputKeystroke = getCurrentElement(props.inputKeystrokes, time);
          setCurrentInputKeystroke(inputKeystroke);
          createInputEvent(inputKeystroke, time);
        } else {
          var inputKeystroke = ""
        }
        var inputScrollAction = getCurrentElement(props.inputScrollActions, time);
        setCurrentInputScrollAction(inputScrollAction);
        createInputScrollEvent(inputScrollAction, time);
        var layoutAction = getCurrentElement(props.layoutActions, time);
        setCurrentLayoutAction(layoutAction);
        createLayoutEvent(layoutAction, time);
        var selectAction = getCurrentElement(props.selectActions, time);
        setCurrentSelectAction(selectAction);
        createMarkdownSelectEvent(selectAction, time);
        var scrollAction = getCurrentElement(props.scrollActions, time);
        setCurrentScrollAction(scrollAction);
        createMarkdownScrollEvent(scrollAction, time);
        var editorScrollAction = getCurrentElement(props.editorScrollActions, time);
        setCurrentEditorScrollAction(editorScrollAction);
        createEditorScrollEvent(editorScrollAction, time);

        if (props.transcript) {
          var transcript = getCurrentElement(props.transcript, time);
          setCurrentTranscript(transcript);
          createTranscriptEvent(transcript, time);
        } else {
          var transcript = "";
        }
        return Promise.resolve([keystroke, consoleAction, consoleScrollAction, inputKeystroke, inputScrollAction, layoutAction, selectAction, scrollAction, editorScrollAction, transcript, time]);
      })
      .then(([keystroke, consoleAction, consoleScrollAction, inputKeystroke, inputScrollAction, layoutAction, selectAction, scrollAction, editorScrollAction, transcript, time]) => {
        if (currentPlayState) {
          props.player.current.audio.current.play();
        }
      });
  }

  const handleSearchResult = (keyword) => {
    // return search result from user's input
    setAudioSearchLoading(true);
    setSearchField(keyword);

    const descriptionSearch = descriptionInnerText.filter((item) => item.toLowerCase().includes(keyword))
    setDescriptionSearchResult(descriptionSearch)
    const numberOfOccurence = descriptionSearch.map((item) => (item.toLowerCase().match(new RegExp(keyword, "g")) || []).length)
    setDescriptionSearchOccurence(numberOfOccurence)

    const requestOptions = {
      method: 'GET'
    }
    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + "/tutorial_section/search/keyword/" + keyword + "/" + props.tutorialSectionId, requestOptions)
      .then(response => response.json())
      .then(data => {
        setAudioSearchLoading(false);
        setAudioSearchResult(data.result);
      })
  }

  const handleStackoverflowSearchResult = (keyword) => {
    // handle search in StackOverflow tab
    if (keyword) {
      setStackoverflowLoading(true);
      const requestOptions = {
        method: 'GET'
      }
      fetch("https://api.stackexchange.com/2.3/search/advanced?page=1&pagesize=5&order=desc&sort=relevance&tagged=" + props.languageChosen + "&body=" + keyword + "&site=stackoverflow&key=" + process.env.REACT_APP_STACKEXCHANGE_KEY + "&filter=withbody", requestOptions)
        .then(response => response.json())
        .then(data => {
          setStackoverflowResult(data.items);
          console.log(data.items[0]);
          setStackoverflowLoading(false);
        })
    }
  }

  const formatTime = (time) => {
    // format time to MM:SS
    const getSeconds = `0${Math.floor(time / 1000)}`.slice(-2);
    const minutes = `0${Math.floor(time / (1000 * 60))}`;
    return `${minutes}:${getSeconds}`
  }

  const handleChooseAudioResult = (time) => {
    var context = document.querySelector("#markdownDiv");
    var instance = new Mark(context);
    instance.unmark();
    setShowClearSearch(false);
    props.setShowSearchModal(false);
    props.player.current.audio.current.currentTime = time / 1000;
    props.player.current.audio.current.playbackRate = playbackSpeed
    props.player.current.audio.current.play();
  }

  const handleTabChange = (event, newValue) => {
    props.setTabValue(newValue);
  };

  const handleChooseDescriptionResult = (i) => {
    props.setShowSearchModal(false);

    var counter = []
    var startIndex;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    var current = descriptionSearchOccurence.slice(0, i);
    if (current.length != 0) {
      startIndex = current.reduce(reducer);
    } else {
      startIndex = 0
    }

    for (var j = startIndex; j < startIndex + descriptionSearchOccurence[i]; j++) {
      counter.push(j)
    }

    var currentCounter = 0;
    var options = {
      filter: function () {
        currentCounter++;
        return counter.includes(currentCounter - 1);
      },
      "each": function (node) {
        node.scrollIntoView({ block: "center" })
      },
    }
    var context = document.querySelector("#markdownDiv");
    var instance = new Mark(context);
    instance.unmark();
    instance.mark(searchField, options);
    setShowClearSearch(true);
  }

  const handleClearDescriptionSearch = () => {
    var context = document.querySelector("#markdownDiv");
    var instance = new Mark(context);
    instance.unmark();
    setShowClearSearch(false);
  }

  const handleOnIdle = () => {
    if (!isIdle) {
      setIsIdle(true);
      clearInterval(countdown.current);
      setTimer(10);
      countdown.current = setInterval(() => {
        setTimer((timer) => timer > 0 ? timer - 1 : 0);
      }, 1000);
    }
  }

  useEffect(() => {
    if (timer == 0) handleLogout();
  }, [timer])

  const {
    reset
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  const handleClose = () => {
    clearInterval(countdown);
    setTimer(10);
    setIsIdle(false);
    reset();
  }

  const handleLogout = async () => {
    const form = new FormData();
    form.append('tutorial_section_id', props.tutorialSectionId);
    form.append('user_id', props.userId);
    const datetime = new Date(startTimeRef.current);
    form.append('datetime', datetime.toISOString());
    form.append('accessed_links', JSON.stringify(accessedLinks));

    const requestOptions = {
      method: 'POST',
      body: form,
      keepalive: true
    };

    await fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + "/upload_learning_recording", requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        const formData = new FormData();
        Object.keys(data.fields).forEach(key => {
          formData.append(key, data.fields[key]);
        })
        formData.append('file', fileRef.current);
        const uploadOptions = {
          method: 'POST',
          body: formData,
          keepalive: true
        };
        fetch(data.url, uploadOptions).then(console.log('uploaded'));
      })
  }


  const recordKeystroke = (e, editor) => {
    const keyEvent = {
      'content': editor.getValue(),
      'selection': editor.getSelectionRange(),
      'timestamp': Date.now() - startTime,
    }
    keystrokes.push(keyEvent);
    zip.file('keystroke.json', JSON.stringify(keystrokes));
    zip.generateAsync({ type: "blob" }).then(blob => {
      fileRef.current = new File([blob], "upload.zip", { type: "application/zip" })
    })
    console.log(keystrokes);
  }

  const recordConsoleAction = (output) => {
    const keyEvent = {
      'content': output,
      'timestamp': Date.now() - startTime,
    }
    consoleActions.push(keyEvent);
    zip.file('consoleAction.json', JSON.stringify(consoleActions));
    zip.generateAsync({ type: "blob" }).then(blob => {
      fileRef.current = new File([blob], "upload.zip", { type: "application/zip" })
    })
    console.log(consoleActions);
  }

  const recordEditorScroll = (scrollTop) => {
    const editorScrollEvent = {
      'timestamp': Date.now() - startTime,
      'scroll': scrollTop,
    }
    editorScrollActions.push(editorScrollEvent);
    zip.file('editorScrollAction.json', JSON.stringify(editorScrollActions));
    zip.generateAsync({ type: "blob" }).then(blob => {
      fileRef.current = new File([blob], "upload.zip", { type: "application/zip" })
    })
    console.log(editorScrollActions);
  }

  const recordMarkdownScroll = () => {
    const scrollEvent = {
      'timestamp': Date.now() - startTime,
      'scroll': getMarkdownScroll(),
    }
    scrollActions.push(scrollEvent);
    zip.file('scrollAction.json', JSON.stringify(scrollActions));
    zip.generateAsync({ type: "blob" }).then(blob => {
      fileRef.current = new File([blob], "upload.zip", { type: "application/zip" })
    })
    console.log(scrollActions);
  }

  const getMarkdownScroll = () => {
    var markdownDivElement = document.getElementById('markdownDiv');
    if (!markdownDivElement) { return }
    var scrollPosition = markdownDivElement.scrollTop;
    var scrollHeight = markdownDivElement.scrollHeight - markdownDivElement.clientHeight;
    var scrollPercent = scrollPosition / scrollHeight;
    return scrollPercent;
  }

  const recordMarkdownSelect = () => {
    var markdownSelect = getMarkdownSelect();
    const selectEvent = {
      'timestamp': Date.now() - startTime,
      'data': markdownSelect,
    }
    selectActions.push(selectEvent);
    zip.file('selectAction.json', JSON.stringify(selectActions));
    zip.generateAsync({ type: "blob" }).then(blob => {
      fileRef.current = new File([blob], "upload.zip", { type: "application/zip" })
    })
    console.log(selectActions);
  }

  const getMarkdownSelect = () => {
    try {
      let sel = window.getSelection();
      let range = sel.getRangeAt(0);
      let startNode = range.startContainer;
      let endNode = range.endContainer;

      if (startNode.nodeType == 3) {
        var startIsText = true;
        var startFlag = startNode.parentNode;
        startNode = startNode.nodeValue;
      } else {
        var startIsText = false;
        var startFlag = startNode;
      }
      if (endNode.nodeType == 3) {
        var endIsText = true;
        var endFlag = endNode.parentNode;
        endNode = endNode.nodeValue;
      } else {
        var endIsText = false;
        var endFlag = endNode;
      }

      let startOffset = range.startOffset;
      let endOffset = range.endOffset;

      let startTagName = startFlag.nodeName;
      let startHTML = startFlag.innerHTML;

      let endTagName = endFlag.nodeName;
      let endHTML = endFlag.innerHTML;

      let rInfo = {
        startNode: startNode,
        startOffset: startOffset,
        startIsText: startIsText,
        startTagName: startTagName,
        startHTML: startHTML,

        endNode: endNode,
        endOffset: endOffset,
        endIsText: endIsText,
        endTagName: endTagName,
        endHTML: endHTML,
      };

      return JSON.stringify(rInfo);
    } catch (e) {
      return null;
    }
  }

  const recordMediaAction = (action, speed) => {
    const mediaEvent = {
      'timestamp': Date.now() - startTime,
      'action': action,
      'speed': speed
    }
    if (action == "seek") {
      mediaEvent.time = Math.round(props.player.current.audio.current.currentTime * 1000);
    }
    mediaActions.push(mediaEvent);
    zip.file('mediaAction.json', JSON.stringify(mediaActions));
    zip.generateAsync({ type: "blob" }).then(blob => {
      fileRef.current = new File([blob], "upload.zip", { type: "application/zip" })
    })
    console.log(fileRef.current);
    console.log(mediaActions);
  }

  useEffect(() => {
    if (startState) {
      if (props.practiceIdeRef.current) {
        const editor = props.practiceIdeRef.current.editor;
        editor.on("changeSelection", recordKeystroke);
        editor.session.on("changeScrollTop", recordEditorScroll);
      }
      var markdownDivElement = document.getElementById('markdownDiv');
      if (markdownDivElement) {
        markdownDivElement.onscroll = recordMarkdownScroll;
        markdownDivElement.onmouseup = recordMarkdownSelect;
      }
    }
  }, [startState])

  useBeforeunload((event) => {
    if (startState) {
      event.preventDefault();
      handleLogout();
      window.location = event.target.href;
    }
  })

  useEffect(() => {
    startTimeRef.current = startTime;
  }, [startTime])

  const handleUpdateTranscript = async (transcript) => {
    // update transcript
    props.setBackdropOpen(true);
    const form = new FormData();
    form.append('tutorial_section_id', props.tutorialSectionId);
    form.append('name', props.tutorialTitle);
    form.append('tutorial_type', 'Code');
    form.append('language', props.languageChosen);
    form.append('code_input', props.input);
    form.append('transcript', JSON.stringify(transcript));

    const requestOptions = {
      method: 'POST',
      body: form
    };
    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + "/upload_recording", requestOptions)
      .then(response => {
        if (response.status === 200) {
          response.json()
            .then(data => {
              const zip = new JSZip();
              zip.file("keystroke.json", JSON.stringify(props.keystrokes));
              zip.file('consoleAction.json', JSON.stringify(props.consoleActions));
              zip.file('consoleScrollAction.json', JSON.stringify(props.consoleScrollActions));
              zip.file('inputKeystrokes.json', JSON.stringify(props.inputKeystrokes));
              zip.file('inputScrollAction.json', JSON.stringify(props.inputScrollActions));
              zip.file('layoutAction.json', JSON.stringify(props.layoutActions));
              zip.file('selectAction.json', JSON.stringify(props.selectActions));
              zip.file('scrollAction.json', JSON.stringify(props.scrollActions));
              zip.file('editorScrollAction.json', JSON.stringify(props.editorScrollActions));
              zip.file('description.md', props.description);
              zip.file('code_content.txt', props.codeContent);
              zip.file('transcript.json', JSON.stringify(transcript));

              if (props.recordingBlob) {
                zip.file('recording.mp3', props.recordingBlob);
              }

              uploadToS3(data, zip);
            })
        }
      })
    return
  }

  const uploadToS3 = async (presignedPostData, zip) => {
    console.log('uploading')
    zip.generateAsync({ type: "blob" })
      .then(blob => {
        var file = new File([blob], "upload.zip", { type: "application/zip" });
        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach(key => {
          formData.append(key, presignedPostData.fields[key]);
        })
        formData.append('file', file);
        const uploadOptions = {
          method: 'POST',
          body: formData
        };

        fetch(presignedPostData.url, uploadOptions)
          .then(response => {
            if (response.status === 204) {
              props.setBackdropOpen(false);
              props.setTranscriptChanged(false);
            }
          })
      })
  }

  const updateTranscript = (data) => {
    props.setTranscript(data);
    props.setTranscriptChanged(true);
  }

  const transcriptEditorTab = () => {
    return {
      id: 'transEditor',
      title: "Transcript Editor",
      cached: true,
      closable: true,
      content: (
        <TranscriptEditor
          transcript={props.transcript}
          updateTranscript={updateTranscript}
          handleUpdateTranscript={handleUpdateTranscript}
          setShowTranscriptEditor={props.setShowTranscriptEditor}
          updateComplete={updateComplete}
          setUpdateComplete={setUpdateComplete}
          saveDisabled={saveDisabled}
          setSaveDisabled={setSaveDisabled}
        />

      )
    }
  }

  const transcriptEditorLayout = {
    dockbox: {
      mode: 'horizontal',
      children: [
        {}
      ]
    },
    floatbox: {
      mode: 'float',
      children: [
        {
          tabs: [transcriptEditorTab()],
          x: 200, y: 200, w: 600, h: 400
        }
      ]
    }
  }

  const handleLayoutChange = newLayout => {
    if (!newLayout.floatbox.children.length) {
      props.setShowTranscriptEditor(false);
    }
  }

  return (
    <div>
      <Grid container spacing={0} className={classes.gridContainer}>
        {/* Body */}
        <Context.Provider
          value={{
            "showCodeRun": showCodeRun,
            "description": props.description,
            "ide": ide,
            "filename": props.filename,
            "random": random,
            "playbackCursor": playbackCursor,
            "practiceCursor": practiceCursor,
            "consoleError": consoleError,
            "setShowSearchModal": props.setShowSearchModal,
            "showClearSearch": showClearSearch
          }}
        >
          <DockLayout
            defaultLayout={defaultLayout}
            ref={props.layoutRef}
            style={{ position: 'absolute', left: 0, top: 55, right: 0, bottom: isPlaybackBarVisible ? 64 : 4 }}
          />
        </Context.Provider>

        {props.showCaption ? (
          <Grid container justify="center" className={classes.captionDiv}>
            <h5 className={classes.caption}>{currentTranscriptText}</h5>
          </Grid>
        ) : null}

        {props.showTranscriptEditor &&
          <DockLayout
            defaultLayout={transcriptEditorLayout}
            onLayoutChange={handleLayoutChange}
            ref={transcriptEditorRef}
            style={{ position: 'absolute', left: 10, top: 60, right: 10, bottom: 10 }}
          />
        }

        {props.showCaption ? (
          <Grid container justify="center" className={classes.captionDiv}>
            <h5 className={classes.caption}>{currentTranscriptText}</h5>
          </Grid>
        ) : null
        }

        {/* Audio Player */}
        <Grid
          container
          className={[classes.gridFooter, isPlaybackBarVisible ? "" : "rhap_hidden"]}
          onMouseEnter={() => setIsPlaybackBarVisible(true)}
          onMouseLeave={() => {
            if (playState && !props.playbackBarAlwaysVisible) {
              setIsPlaybackBarVisible(false)
            }
          }}
        >
          <AudioPlayer
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            ref={props.player}
            src={props.recordingSrc}
            customProgressBarSection={
              [
                RHAP_UI.PROGRESS_BAR
              ]
            }
            customControlsSection={
              [
                RHAP_UI.ADDITIONAL_CONTROLS,
                RHAP_UI.MAIN_CONTROLS,
                RHAP_UI.VOLUME_CONTROLS,
                RHAP_UI.CURRENT_TIME,
                <span className="rhap_time-slash">/</span>,
                RHAP_UI.DURATION,
                <div className="rhap_other_controls">
                  {/* <Button round className="rhap_next" disabled={true} color="primary">
                    Next Lesson
                  </Button> */}
                </div>

              ]
            }
            onPlay={() => {
              play();
              recordMediaAction("play", playbackSpeed);
            }}
            onPause={() => {
              pause();
              recordMediaAction("pause", playbackSpeed);
            }}
            onSeeked={() => {
              seeked();
              recordMediaAction("seek", playbackSpeed);
            }}
            onEnded={() => {
              recordMediaAction("end", playbackSpeed);
            }}
            customAdditionalControls={[
              <div>
                <IconButton
                  onClick={(e) => setPlaybackSettingsAnchor(e.currentTarget)}
                ><SettingsIcon />
                </IconButton>
                <Menu
                  keepMounted
                  open={playbackSettingsAnchor !== null}
                  anchorEl={playbackSettingsAnchor}
                  onClose={() => setPlaybackSettingsAnchor(null)}
                >
                  <MenuItem onClick={() => props.setShowCaption(!props.showCaption)}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>Caption</Grid>
                      <Grid item >
                        <Chip label={props.showCaption ? "On" : "Off"} size="small" />
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <NestedMenuItem
                    label="Playback speed"
                    parentMenuOpen={true}
                  >
                    {[0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((val, index) =>
                      <MenuItem
                        value={val}
                        key={index}
                        selected={playbackSpeed === val}
                        onClick={e => {
                          setPlaybackSpeed(val);
                          recordMediaAction("changeSpeed", val);
                          pause();
                          seeked();
                          props.player.current.audio.current.playbackRate = val
                        }}
                      >
                        {val}
                      </MenuItem>
                    )}
                  </NestedMenuItem>
                </Menu>
              </div>
            ]}
          />
        </Grid>
      </Grid>

      <Modal show={isIdle} onHide={() => { handleClose() }}>
        <Modal.Header closeButton>
          <Modal.Title>You Have Been Idle!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You Will Get Timed Out. You want to stay?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => { handleLogout() }}>
            Logout({timer}s)
          </Button>
          <Button variant="primary" onClick={() => { handleClose() }}>
            Stay
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Search Dialog */}
      <Modal show={props.showSearchModal} onHide={() => props.setShowSearchModal(false)} centered dialogClassName="searchModal" scrollable={true} size='xl'>
        <Modal.Header closeButton id="classic-modal-slide-title" className={classes.modalHeader}>
          <Tabs
            value={props.tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            <Tab label="Tutorial" />
            <Tab label="StackOverflow" />
          </Tabs>
        </Modal.Header>
        <Modal.Body id="modal-slide-description" className={classes.searchModal}>
          <TabPanel value={props.tabValue} index={0}>
            <CustomInput
              id="regular"
              inputProps={{
                value: searchField,
                placeholder: "Type your keywords here to search from Tutorial",
                onChange: (e) => {
                  setSearchField(e.target.value)
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleSearchResult(searchField.toLowerCase())}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              formControlProps={{
                style: {
                  padding: "0px",
                  margin: "0px",
                  width: "100%"
                }
              }}
            />
            <div className={classes.searchResult}>
              <div className={classes.topFiveResult}>
                {frequentWord && (
                  frequentWord.map((data) => {
                    return (
                      <li key={data.key}>
                        <Chip
                          label={data.label}
                          className={classes.chip}
                          onClick={() => {
                            handleSearchResult(data.label.toLowerCase());
                          }}
                        />
                      </li>
                    );
                  })
                )}
              </div>
              {audioSearchResult && !audioSearchLoading &&
                (
                  <List
                    subheader={
                      <ListSubheader component="div" className={classes.listSubheader}>
                        Search Result
                      </ListSubheader>
                    }
                  >
                    {audioSearchResult.length != 0 ?
                      (audioSearchResult.map((item) => {
                        return (
                          <ListItem button component="a" key={item.timestamp} href={item.link} target="_blank" onClick={() => { handleChooseAudioResult(item.timestamp) }}>
                            <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={[searchField]}
                              autoEscape={true}
                              textToHighlight={formatTime(item.timestamp) + " | " + item.text}
                            />
                          </ListItem>
                        )
                      })) : (
                        <p>No result found in Audio</p>
                      )
                    }
                  </List>
                )
              }
              {descriptionSearchResult &&
                (
                  <List
                    subheader={
                      <ListSubheader component="div" className={classes.listSubheader}>
                        Search Result
                      </ListSubheader>
                    }
                  >
                    {descriptionSearchResult.length != 0 ?
                      (descriptionSearchResult.map((item, i) => {
                        return (
                          <ListItem button key={i} onClick={() => handleChooseDescriptionResult(i)}>
                            <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={[searchField]}
                              autoEscape={true}
                              textToHighlight={item}
                            />
                          </ListItem>
                        )
                      })) : (
                        <p>No result found in Description</p>
                      )
                    }
                  </List>
                )
              }
            </div>
          </TabPanel>
          <TabPanel value={props.tabValue} index={1}>
            <CustomInput
              id="regular"
              inputProps={{
                value: stackoverflowSearchField,
                placeholder: "Type your keywords here to search StackOverflow",
                onChange: (e) => {
                  setStackoverflowSearchField(e.target.value)
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleStackoverflowSearchResult(stackoverflowSearchField)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              formControlProps={{
                style: {
                  padding: "0px",
                  margin: "0px",
                  width: "100%"
                }
              }}
            />
            <div className={classes.searchResult}>
              {!stackoverflowLoading && stackoverflowResult &&
                (
                  <List
                    subheader={
                      <ListSubheader component="div" className={classes.listSubheader}>
                        Search Result
                      </ListSubheader>
                    }
                  >
                    {stackoverflowResult.map((item) => {
                      return (
                        <ListItem button component="a" key={item.link} href={item.link} target="_blank">
                          <ListItemText primary={decode(item.title)} secondary={removeTagsAndCodes(item.body)} />
                        </ListItem>
                      )
                    })
                    }
                  </List>
                )
              }

              {consoleError && !stackoverflowLoading && stackoverflowSuggestedResult &&
                (
                  <List
                    subheader={
                      <ListSubheader component="div" className={classes.listSubheader}>
                        Suggested Threads
                      </ListSubheader>
                    }
                  >
                    {stackoverflowSuggestedResult.map((item) => {
                      return (
                        <ListItem button component="a" key={item.link} href={item.link} target="_blank" onClick={() => { recordAccessedLink(item.link) }}>
                          <ListItemText primary={decode(item.title)} secondary={removeTagsAndCodes(item.body)} />
                        </ListItem>
                      )
                    })
                    }
                  </List>
                )
              }
              {stackoverflowLoading &&
                (
                  <div className={classes.stackoverflowLoading}>
                    <CircularProgress />
                  </div>
                )
              }

            </div>
          </TabPanel>
        </Modal.Body>
      </Modal>

      <Backdrop className={classes.backdrop} open={props.backdropOpen}>
        <CircularProgress />
      </Backdrop>

    </div>
  );
}
