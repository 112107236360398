/*eslint-disable*/
import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import Typography from "@material-ui/core/Typography";
// core components
import Button from "components/CustomButtons/Button.js";

import styles, { sideHeaderLink } from "assets/jss/material-kit-react/components/headerLinksStyle.js";

export default function SideBarLinks({ side, tutorialName, sequence, id, pid }) {

  const StepIconComponent = (i) => {
    return i === pid - 1 ? <FiberManualRecordIcon color="primary" /> : <FiberManualRecordOutlinedIcon color="disabled" />
  }
  var useStyles = makeStyles(styles)
  if (side) {
    useStyles = makeStyles(sideHeaderLink);
  }
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>

        <Button
          startIcon={<ArrowBackIosIcon />}
          href="/tutorial"
          color="transparent"
          className={classes.navLink}
        >
          Back
        </Button>
      </ListItem>
      <Stepper orientation="vertical" activeStep={pid - 1}>
        <Typography variant="h6" component="div" gutterBottom>
          {tutorialName}
        </Typography>
        {sequence && sequence.map((s, i) =>
          <Step
            key={i}
            onClick={() => {
              if (i !== pid - 1)
                window.location.href = "/tutorial/" + id + "/" + (parseInt(i) + 1);
            }}
          >
            <StepLabel
              style={{ cursor: "pointer" }}
              StepIconComponent={() => StepIconComponent(i)}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {s.tutorial_type === "Question" ? <AssignmentIcon fontSize="small" /> : <OndemandVideoIcon fontSize="small" />}
                </Grid>
                <Grid item>
                  {s.name}
                  <Typography variant="caption" component="div">
                    {s.tutorial_type === "Question" ?
                      "Not Graded"
                      : `${Math.ceil(s.duration / 60000)} min${Math.ceil(s.duration / 60000) > 1 ? "s" : ""}`}
                  </Typography>
                </Grid>
              </Grid>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    </List>
  );
}
