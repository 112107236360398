import React, { useState } from "react";
import { login, useAuth } from "../../auth";
import { useHistory, Redirect, useParams } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from '@material-ui/core/Snackbar';
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function ResetPasswordPage(props) {
  const { tenant } = useParams();
  const classes = useStyles();
  const { ...rest } = props;

  const history = useHistory();

  const [role, setRole] = useState("learner");
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newRePassword, setNewRePassword] = useState('');
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('Please try again');
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState('Please try again');

  const [logged] = useAuth();


  const handleClose = (event, reason) => {
    // Handles snackbar open state
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onSubmitClick = (e) => {
    // Handles form submission login
    e.preventDefault();
    if (email && oldPassword && newPassword && newRePassword && role && (oldPassword !== newPassword) && (newPassword === newRePassword)) {
      let opts = {
        'email': email,
        'old_password': oldPassword,
        'new_password': newPassword,
        'new_password_confirm': newRePassword,
        'role': role,
      };
      localStorage.removeItem("session_id");
      fetch(process.env.REACT_APP_AUTH_URL + "/" + tenant + '/auth/change_password', {
        method: 'post',
        body: JSON.stringify(opts)
      }).then(r => {
        if (r.status === 200) {
          setTimeout(() => {

          setSuccessMsg("Reset password successfully");
          setOpenSuccess(true);
        }, 1);

          history.push({ pathname: "/"+ tenant +"/login" });

        } else {
          return r.json()
        }
      }).then(data => {
        if (data && data.message) {
          setErrorMsg(data.message)
          setOpen(true);
        }
      })
    } else if (oldPassword === newPassword) {
      setErrorMsg("Old password cannot be the same as new password");
    }
    else {
      setErrorMsg("Invalid fields");
      setOpen(true);
    }
  }




  return (
    // main part where it will auto log in you to /tutorial once u logged in after running login function
    logged && localStorage.getItem("session_id") !== null ? <Redirect to='/tutorial' /> :
      <div>
        <Header
          absolute
          color="transparent"
          brand="Interactive Tutorial System"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h3>Reset Password</h3>
                    </CardHeader>
                    <CardBody>
                      <div
                        className={
                          classes.checkboxAndRadio
                        }
                        style={{ textAlign: "center" }}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={role === "learner"}
                              onChange={() => setRole("learner")}
                              value="learner"
                              name="radio button enabled"
                              aria-label="learner"
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label="Learner"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={role === "author"}
                              onChange={() => setRole("author")}
                              value="author"
                              name="radio button enabled"
                              aria-label="author"
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label="Author"
                        />
                        {/* <FormControlLabel
                        control={
                          <Radio
                            checked={role === "admin"}
                            onChange={() => setRole("admin")}
                            value="admin"
                            name="radio button enabled"
                            aria-label="admin"
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Admin"
                      /> */}
                      </div>
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setEmail(e.target.value),
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        labelText="Old Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setOldPassword(e.target.value),
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                      <CustomInput
                        labelText="New Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setNewPassword(e.target.value),
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                      <CustomInput
                        labelText="Confirm New Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setNewRePassword(e.target.value),
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button color="github" onClick={onSubmitClick} type="submit" fullWidth={true}>
                        Reset Password
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <SnackbarContent
                message={
                  <span>
                    {errorMsg}
                  </span>
                }
                color="danger"
              />
            </Snackbar>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={openSuccess}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <SnackbarContent
                message={
                  <span>
                    {successMsg}
                  </span>
                }
                color="success"
              />
            </Snackbar>
          </div>
          <Footer whiteFont />
        </div>
      </div>
  );
}
