import React, {useState, useEffect, useRef} from 'react';
import {useTable, useRowSelect} from 'react-table';
import parse from "html-react-parser";
import Button from "components/CustomButtons/Button.js";

export default function TestTable({ columns, data, updateData, removeRow}){
    const EditableTextArea = ({
        value: initialValue,
        row: {index},
        column: {id},
        updateData,
    }) => {
        const [value, setValue] = useState(initialValue)
        const [textInput, setTextInput] = useState(false)
        const focusInput = useRef(null);

        const onChange = e => {
            setValue(e.target.value)
        }

        const onBlur = () => {
            setTextInput(false)
            updateData(index, id, value)
        }

        useEffect(() => {
            if (textInput) {
                focusInput.current.focus()
            }
        }, [textInput])

        useEffect(() => {
            setValue(initialValue)
        }, [initialValue])
        
        return (
            <div onClick={() => {setTextInput(true)}}>
                {textInput
                    ? <textarea onChange={onChange} onBlur={onBlur} row="5" cols="15" value={value} ref={focusInput}/>
                    : <span>{value == "" ? <i>Click to enter text</i>:parse(value.replace("\n","<br>"))}</span>
                }
            </div>
        );
    }

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
    
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
    
        return (
            <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
        }
    )

    const defaultColumn = {
        Cell: EditableTextArea
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: { selectedRowIds }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            updateData,
            removeRow,
        },
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns =>  [
                {
                    id:'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    )
    
    return (
        <>
            <table {...getTableProps()}>
            <thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map(column => (
						<th {...column.getHeaderProps()}>{column.render('Header')}</th>
					))}
					</tr>
				))}
				</thead>
				<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row)
					return (
					<tr {...row.getRowProps()}>
						{row.cells.map(cell => {
							if(cell.column.Header === 'Transcript'){
								return <td {...cell.getCellProps()}>{cell.render('TextArea')}</td>
							}
							return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
						})}
					</tr>
					)
				})}
				</tbody>
            </table>
            <Button size="sm" onClick={() => removeRow(selectedRowIds)}>Remove Checked Rows</Button>
        </>
    )


}