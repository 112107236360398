import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Drawer from '@material-ui/core/Drawer';
import styles from "assets/jss/material-kit-react/components/sideBarStyle.js";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import SideBarLinks from "./SideBarLinks";

const useStyles = makeStyles(styles);

export default function SideBar({ id, pid }) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sequence, setSequence] = useState(null);
  const [tutorialName, setTutorialName] = useState("")
  useEffect(() => {
    if (id) {
      const requestOptions = {
        method: 'GET',
      };

      fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/tutorial_section/get/tutorial_id/' + id, requestOptions)
        .then(response => response.json())
        .then(data => {
          setSequence(data.tutorial_section);
        });

    }
  }, [id])

  useEffect(() => {
    if (id || sequence) {
      const requestOptions = {
        method: 'GET',
      };

      fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/tutorial/get/' + id, requestOptions)
        .then(response => response.json())
        .then(data => {
          setTutorialName(data.name)
        })
    }
  }, [id])
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.container}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
      >
        <Menu />
      </IconButton>
      <Drawer
        variant="temporary"
        anchor={"left"}
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
      >
        <div className={classes.appResponsive}>

          <SideBarLinks side={true} tutorialName={tutorialName} sequence={sequence} id={id} pid={pid} />
        </div>
      </Drawer>
    </div>
  )
}

