import React from 'react'
import PropTypes from 'prop-types' // ES6
import MicRecorder from 'mic-recorder-to-mp3'

export const RecordState = Object.freeze({
  START: 'start',
  STOP: 'stop',
  NONE: 'none'
})

export default class Mp3ReactRecorder extends React.Component {
  //0 - constructor
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()
    this.Mp3Recorder = new MicRecorder({ bitRate: 128})
  }

  //TODO: add the props definitions
  static propTypes = {
    state: PropTypes.string,
    type: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    canvasWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    canvasHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    //method calls
    onStop: PropTypes.func
  }

  static defaultProps = {
    state: RecordState.NONE,
    type: 'audio/mp3',
    backgroundColor: 'rgb(200, 200, 200)',
    foregroundColor: 'rgb(0, 0, 0)',
    canvasWidth: 500,
    canvasHeight: 300
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this.props

    this.checkState(prevProps.state, state)
  }

  checkState(previousState) {
    switch (previousState) {
      case RecordState.START:
        this.doIfState(RecordState.STOP, this.stop)
        break
      case RecordState.STOP:
        this.doIfState(RecordState.START, this.start)
        break
      default:
        this.doIfState(RecordState.START, this.start)
        break
    }
  }

  doIfState(state, cb) {
    if (this.props.state == state) {
      cb && cb()
    }
  }

  start = () => {
    this.Mp3Recorder.start()
    .catch((e) => console.log(e));
  }

  stop = () => {
    const { onStop, type } = this.props

    this.Mp3Recorder.stop()
    .getMp3()
    .then(([buffer, blob]) => {
      const blobURL = URL.createObjectURL(blob);

      onStop &&
        onStop({
          blob: blob,
          url: blobURL,
          type
        })
    }).catch((e) => console.log(e));

  }
  //1 - render
  render() {
    const { canvasWidth, canvasHeight } = this.props

    return (
      <div className='audio-react-recorder'>
        <canvas
          ref={this.canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          className='audio-react-recorder__canvas'
        ></canvas>
      </div>
    )
  }
}