import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import SideBar from "components/SideBar/SideBar.js";
import Button from "components/CustomButtons/Button.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItem, MenuList } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Snackbar from '@material-ui/core/Snackbar';
// material ui icon
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SaveIcon from '@material-ui/icons/Save';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextRotateUpIcon from '@material-ui/icons/TextRotateUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import QuestionSection from './Sections/QuestionSection.js';
import CodeSection from './Sections/CodeSection.js';

import styles from "assets/jss/material-kit-react/views/tutorialSectionPage.js";

import sample from './Sections/sample.js'

import JSZip from "jszip";
// tour
import Joyride, { STATUS } from 'react-joyride';

const useStyles = makeStyles(styles);

const {sampleQuestion} =sample;
export default function TutorialPage(props) {
  const { id, pid } = useParams();
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();

  const layoutRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [tourStart, setTourStart] = useState(false);
  const [preTourLayout, setPreTourLayout] = useState(null);
  const [refreshedLayout, setRefreshedLayout] = useState();
  const [typeChosen, setTypeChosen] = useState(null);
  const [tutorialId, setTutorialId] = useState("");
  const [tutorialType, setTutorialType] = useState("");
  const [languageChosen, setLanguageChosen] = useState("");
  const [iframePy, setIframePy] = useState("3")
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [ide, setIde] = useState("");
  const [ideEditted, setIdeEditted] = useState("");
  const [input, setInput] = useState("")
  const [inputEditted, setInputEditted] = useState("")
  const [question, setQuestion] = useState(null);
  const [tutorialSectionTitle, setTutorialSectionTitle] = useState("Untitled Section");
  const [filename, setFilename] = useState("");
  const [editTitleButton, setEditTitleButton] = useState(false);
  const [sectionSequence, setSectionSequence] = useState(null);
  const [recordingFound, setRecordingFound] = useState(false);
  const [recordStartState, setRecordStartState] = useState(false);
  const [helpAnchor, setHelpAnchor] = useState(null);
  const [layoutAnchor, setLayoutAnchor] = useState(null);
  const [authorLayout, setAuthorLayout] = useState();


  const steps = [
    {
      content: <h4>Welcome to the Interactive Tutorial System Author Tour.</h4>,
      target: 'body',
      placement: 'center',
      placementBeacon: 'top',
      spotlightClicks: false,
    },
    {
      content: <h4>Click the <EditIcon style={{ fontSize: "1.125rem" }} /> icon to update the tutorial section name.</h4>,
      target: '#section-name-div'
    },
    {
      content: <h4>You can upload a markdown (.md) file for this tutorial section's description.</h4>,
      target: '#upload-button'
    },
    {
      content: <h4>Alternatively, you can directly type text in the <b>Description</b> tab. You can then preview the description in the <b>Preview</b> tab.</h4>,
      target: '.dock.dock-top',
      placement: 'right'
    },
    {
      content: <h4>To aid in learning, do add relevant images and ensure that these images are presented near relevant text.</h4>,
      target: '.dock.dock-top',
      placement: 'right'
    },
    {
      content: <h4>Scrolling and text selection will be recorded. Utilize these to better direct the learner's attention.</h4>,
      target: '.dock.dock-top',
      placement: 'right'
    },
    {
      content: <h4>Before you start recording, you can resize and maximize the panels, close unnecessary tabs and drag tabs around. These interactions will also be recorded during recording.</h4>,
      target: '.dock-layout',
      placement: 'top-end',
      hideBackButton: true,
    },
    {
      content: <h4>You can save and load your own customized layout for the rest of this tutorial. You can also restore to the default layout.</h4>,
      target: '#save-load-layout-buttons',
    },
    {
      content: <h4>You can search for relevant questions from Stack Overflow. Suggested Threads will also appear when there is an error in the <b>Output</b> tab.</h4>,
      target: '#search-button',
    },
    {
      content: <h4>Once you're all set, click this button to start recording!</h4>,
      target: '#start-recording-button',
      spotlightClicks: false,
    },
    {
      content: <h4>Recording with audio is recommended. Do enable your microphone for audio recording. If no microphone is detected, audio will not be recorded.</h4>,
      target: '#start-recording-button',
      spotlightClicks: false,
    },
    {
      content: <h4>When you have finished recording, you can preview the recording here.</h4>,
      target: '#preview-button',
      spotlightClicks: false,
    },
    {
      content:
        <div>
          <h4>This brings us to the end of the tour.</h4>
          <h5>To aid in learning, do refer to this <a href="https://waterbearlearning.com/mayers-principles-multimedia-learning/" target="_blank"><u>link</u></a> for more information on Mayer's Principles of Multimedia Learning.</h5>
        </div>,
      target: 'body',
      placement: 'center',
      placementBeacon: 'top',
      spotlightClicks: false,
    }
  ]

  const setTourStartState = () => {
    // Set to default layout for the tour
    setPreTourLayout(layoutRef.current.saveLayout());
    layoutRef.current.loadLayout(refreshedLayout);
  }

  useEffect(() => {
    if (id) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      };

      fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/tutorial_section/get/' + id, requestOptions)
        .then(response => response.json())
        .then(data => {
          setTutorialType(data.tutorial_type)
          setLanguageChosen(data.language)
          if (data.language == 'java') {
            setIframePy('java');
          }
          setTypeChosen(data.tutorial_type)
          setVersion(data.version);
          setTutorialSectionTitle(data.name)
          setFilename(data.name.replace(/[^A-Z0-9]/ig, '_').toLowerCase())
          setTutorialId(data.tutorial_id)
          setSectionSequence(data.sequence)

          if (data.code_input) {
            setInput(data.code_input)
          }

          if (data.tutorial_type == "Question" && data.question) {
            const questionData = JSON.parse(data.question)
            setQuestion(questionData)
          }

          if (data.tutorial_type == "Code" && data.system_ver == 1) {
            if (data.code_content) {
              setIde(data.code_content)
            }

            if (data.description) {
              setDescription(data.description)
            }

            if (data.recording != null) {
              setRecordingFound(true);
            }
          }

          if (data.tutorial_type == "Code" && data.system_ver == 2) {
            fetchZipFile(data.zipFileURL)
          }

          setRefreshedLayout({
            dockbox: {
              mode: 'horizontal',
              children: [
                {
                  size: 400,
                  tabs: [{ id: 'description' }, { id: 'description-preview' }],
                },
                {
                  mode: 'vertical',
                  size: 600,
                  children: [
                    {
                      size: 600,
                      tabs: [{ id: 'editor' }]
                    },
                    {
                      mode: 'horizontal',
                      size: 400,
                      children: [
                        {
                          size: 100,
                          tabs: [{ id: 'input' }]
                        },
                        {
                          tabs: [{ id: 'output' }, { id: data.language + '-tutor' }]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          });
        });
    }
  }, [id])

  const fetchZipFile = (zipURL) => {
    fetch(zipURL)       
    .then(function (response) {   
        if (response.status === 200 || response.status === 0) {
            return Promise.resolve(response.blob());
        }
    })
    .then(JSZip.loadAsync)                           
    .then(function (zip) {
      parseZip(zip); 
    })
  }

  const parseZip = (zip) => {
    zip.file("description.md").async("string")
      .then(text => {
        setDescription(text);
      })

    zip.file("code_content.txt").async("string")
      .then(text => {
        setIde(text);
      })
    
    try {
      zip.file("recording.mp3").async("blob")
        .then(blob => {
          setRecordingFound(true);
        }).catch(err => {
  
        })
    } catch (error) {
      
    }
  }

  const handleJoyrideCallback = (data) => {
    // Code for tour
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTourStart(false);
      layoutRef.current.loadLayout(preTourLayout);
    }
  };

  const handleSaveTutorialSection = () => {
    if (tutorialType == "Code") {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          name: tutorialSectionTitle,
          language: languageChosen,
          description: description,
          code_content: ideEditted,
          code_input: inputEditted,
        })
      };

      fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/tutorial_section/update/' + id, requestOptions)
        .then(response => response.json())
        .then(data => history.push("/tutorial/overview/" + data.tutorial_id));
    } else {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          name: tutorialSectionTitle,
          question: question,
        })
      };

      fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/tutorial_section/update/' + id, requestOptions)
        .then(response => {
          if (response.status == 200) {
            history.push("/tutorial/overview/" + tutorialId)
          }
        })
    }
  }

  const handleDescriptionChange = (value) => {
    setDescription(value)
  }

  const handleIdeChange = (value) => {
    setIdeEditted(value)
  }

  const handleInputIdeChange = (value) => {
    setInputEditted(value)
  }

  const handleQuestionChange = (value) => {
    setQuestion(value)
  }

  const handleRecordStartStateChange = (value) => {
    setRecordStartState(value)
  }

  const saveLayout = () => {
    var newLayout = layoutRef.current.saveLayout();
    setAuthorLayout(newLayout);

    const saveDetails = {
      'userid': props.userId,
      'tutorialid': tutorialId,
      'layout': newLayout,
      'role': props.role,
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/save_learner_layout', {
      method: 'POST',
      body: JSON.stringify(saveDetails)
    })
  }
  return (
    <div className={classes.container}>
      {typeChosen == "Code" &&
        <Joyride
          callback={handleJoyrideCallback}
          steps={steps}
          run={tourStart}
          continuous={true}
          disableCloseOnEsc={true}
          showSkipButton={true}
          spotlightClicks={true}
          disableOverlayClose={true}
          locale={{ back: 'Back', close: 'Close', last: 'Next', next: 'Next', skip: 'Skip' }}
          styles={
            {
              buttonClose: {
                display: 'none',
              },
            }
          }
        />
      }
      <Grid container spacing={0} className={classes.gridContainer}>
        <Grid item xs={8} className={classes.gridHeaderLeft}>
          <Button size="sm" onClick={() => handleSaveTutorialSection()} disabled={recordStartState} style={{ margin: "0 8px" }}>
            <ExitToAppIcon />
            Back
          </Button>
          {editTitleButton
            ? (
              <div className={classes.inputContainer} id="section-name-div">
                <CustomInput
                  id="regular"
                  inputProps={{
                    defaultValue: tutorialSectionTitle,
                    placeholder: "Section Name",
                    onChange: (e) => {
                      setTutorialSectionTitle(e.target.value)
                    }
                  }}
                  formControlProps={{
                    style: {
                      padding: "0px",
                      margin: "0px",
                    }
                  }}
                />
                <CheckIcon fontSize="small" style={{ marginLeft: "10px", cursor: "pointer", color: "green" }} onClick={() => { setEditTitleButton(false); setFilename(tutorialSectionTitle.replace(/[^A-Z0-9]/ig, '_').toLowerCase()) }} />
              </div>
            )
            : (
              <div className={classes.inputContainer} id="section-name-div">
                <h5 className={classes.title}>{tutorialSectionTitle}</h5>
                <EditIcon fontSize="small" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => setEditTitleButton(true)} />
              </div>
            )
          }
        </Grid>
        <Grid item xs={4} className={classes.gridHeaderRight}>
          {typeChosen == "Code" &&
            <div>
              <Button
                className={isSmallScreen && classes.menuButtonDense}
                size="sm"
                link
                onClick={(e) => setLayoutAnchor(e.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}

              >
                Layout
              </Button>

              <Menu
                anchorEl={layoutAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(layoutAnchor)}
                onClose={() => setLayoutAnchor(null)}
                TransitionComponent={Fade}
              >
                <MenuList dense>
                  <MenuItem onClick={() => saveLayout()}>
                    <ListItemIcon>
                      <SaveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Save Layout</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => layoutRef.current.loadLayout(authorLayout)}>
                    <ListItemIcon>
                      <ViewCompactIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Load Saved Layout</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => layoutRef.current.loadLayout(refreshedLayout)}>
                    <ListItemIcon>
                      <RefreshIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Restore Layout</ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
              <Button
                className={isSmallScreen && classes.menuButtonDense}
                size="sm"
                link
                onClick={(e) => setHelpAnchor(e.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Help
              </Button>
              <Menu
                anchorEl={helpAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(helpAnchor)}
                onClose={() => setHelpAnchor(null)}
                TransitionComponent={Fade}
              >
                <MenuList dense>
                  <MenuItem
                    onClick={() => {
                      setHelpAnchor(null)
                      setTourStartState();
                      setTourStart(true);
                    }}>
                    Play Onboarding Tutorial
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          }
          {typeChosen == "Question" &&
            <div>
              <Hidden xsDown>
                <Button disabled={question} style={{ margin: "3px 1px" }} onClick={() => handleQuestionChange(sampleQuestion)}>
                  Upload Sample
                </Button>
              </Hidden>
              <Hidden smUp>
                <Tooltip title="Upload Sample">
                  <Button disabled={question} style={{ margin: "3px 1px" }} justIcon onClick={() => handleQuestionChange(sampleQuestion)}>
                    <TextRotateUpIcon />
                  </Button>
                </Tooltip>
              </Hidden>
              <Tooltip title="Preview Section" >
                <Link to={`/tutorial/${tutorialId}/${sectionSequence}`} target="_blank">
                  <Button id="preview-button" style={{ margin: "3px 1px" }} justIcon className={classes.previewButton}><VisibilityIcon /></Button>
                </Link>
              </Tooltip>
            </div>
          }

          <Hidden smUp>
            <Tooltip title="Back">
              <Button justIcon onClick={() => handleSaveTutorialSection()} disabled={recordStartState} style={{ margin: "3px 1px" }}>
                <ExitToAppIcon />
              </Button>
            </Tooltip>
          </Hidden>
        </Grid>
        {typeChosen == "Code" &&
          <CodeSection
            description={description}
            ide={ide}
            input={input}
            languageChosen={languageChosen}
            iframePy={iframePy}
            version={version}
            tutorialId={tutorialId}
            tutorialSectionTitle={tutorialSectionTitle}
            filename={filename}
            userId={props.userId}
            role={props.role}
            layoutRef={layoutRef}
            handleDescriptionChange={handleDescriptionChange}
            handleIdeChange={handleIdeChange}
            handleInputIdeChange={handleInputIdeChange}
            sectionSequence={sectionSequence}
            recordingFound={recordingFound}
            recordStartState={recordStartState}
            handleRecordStartStateChange={handleRecordStartStateChange}
            setAuthorLayout={setAuthorLayout}
          />
        }
        {typeChosen == "Question" &&
          <QuestionSection
            question={question ? question : null}
            tutorialSectionTitle={tutorialSectionTitle}
            id={id}
            tutorialId={tutorialId}
            handleQuestionChange={handleQuestionChange}
          />
        }
      </Grid>
    </div>
  );
}
