import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import TutorialHeader from "components/TutorialHeader/TutorialHeader.js";
// core components
import CodeSection from './Sections/CodeSection.js';
import QuestionSection from './Sections/QuestionSection.js';

import Grid from '@material-ui/core/Grid';
import { saveAs } from 'file-saver';
// tour
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import { createSilentAudio } from 'create-silent-audio';

import styles from "assets/jss/material-kit-react/views/TutorialPage.js";

import { useBeforeunload } from 'react-beforeunload';

import JSZip from 'jszip';

const useStyles = makeStyles(styles);

export default function TutorialPage(props) {
  const { id, pid } = useParams();
  const classes = useStyles();

  const layoutRef = useRef(null);
  const inputIdeRef = useRef(null);
  const playbackIdeRef = useRef(null);
  const consoleIdeRef = useRef(null);
  const player = useRef(null);
  const practiceIdeRef = useRef(null);

  const [tourStart, setTourStart] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [preTourLayout, setPreTourLayout] = useState(null);
  const [preTourCode, setPreTourCode] = useState("");
  const [preTourInput, setPreTourInput] = useState("");
  const [preTourOutput, setPreTourOutput] = useState("");

  const [tutorialTitle, setTutorialTitle] = useState("");
  const [filename, setFilename] = useState("");
  const [pageNumber, setPageNumber] = useState("");
  const [pageNames, setPageNames] = useState([]);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [tutorialType, setTutorialType] = useState("");
  const [learnerLayout, setLearnerLayout] = useState();
  const [refreshedLayout, setRefreshedLayout] = useState();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [frequentWord, setFrequentWord] = useState([]);
  const [tutorialSectionId, setTutorialSectionId] = useState(null)

  const [description, setDescription] = useState("");
  const [inputIde, setInputIde] = useState("");
  const [languageChosen, setLanguageChosen] = useState("");
  const [iframePy, setIframePy] = useState("3")
  const [version, setVersion] = useState("");
  const [themeChosen, setThemeChosen] = useState("github");
  const [keystrokes, setKeystrokes] = useState([]);
  const [consoleActions, setConsoleActions] = useState([]);
  const [consoleScrollActions, setConsoleScrollActions] = useState([]);
  const [inputKeystrokes, setInputKeystrokes] = useState([]);
  const [inputScrollActions, setInputScrollActions] = useState([]);
  const [layoutActions, setLayoutActions] = useState([]);
  const [selectActions, setSelectActions] = useState([]);
  const [scrollActions, setScrollActions] = useState([]);
  const [editorScrollActions, setEditorScrollActions] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [codeContent, setCodeContent] = useState("");
  const [practiceCode, setPracticeCode] = useState({});
  const [ideChanged, setIdeChanged] = useState(false);
  const [transcriptChanged, setTranscriptChanged] = useState(false);

  const [recordingSrc, setRecordingSrc] = useState("");
  const [recordingBlob, setRecordingBlob] = useState();

  const [showCaption, setShowCaption] = useState(true);
  const [playbackBarAlwaysVisible, setPlaybackBarAlwaysVisible] = useState(false);

  const [question, setQuestion] = useState(null);
  const [showTranscriptEditor, setShowTranscriptEditor] = useState(false);
  const [chipText, setChipText] = useState(null);
  const [chipSuccess, setChipSuccess] = useState(null);
  const [chipVisible, setChipVisible] = useState(false);
  const steps = [
    {
      content: <h4>Welcome to the Interactive Tutorial System Learner Tour.</h4>,
      target: 'body',
      placement: 'center',
      placementBeacon: 'top',
    },
    {
      content: <h4>This is the <b>Description</b> tab.</h4>,
      target: '.dock.dock-top',
      placement: 'right',
      spotlightClicks: true,
    },
    {
      content: <h4>This is the code pane. The author's recording will playback in the <b>Playback</b> tab. You can practice on your own in the <b>Practice</b> tab.</h4>,
      target: '.dock-box.dock-vbox > .dock-panel > .dock.dock-top',
    },
    {
      content: <h4>Let us now try to print some inputs. In the <b>Input</b> tab, we see that <code>"python"</code> is the first input and <code>"java"</code> is the second input.</h4>,
      target: '.dock-box.dock-vbox > .dock-box.dock-hbox > .dock-panel > .dock.dock-top',
    },
    {
      content: <h4>Code that takes in the two inputs and prints them has been added.</h4>,
      target: '.dock-box.dock-vbox > .dock-panel > .dock.dock-top',
    },
    {
      content: <h4>We can run the code now.</h4>,
      target: '#playback-code-run-button',
    },
    {
      content: <h4>The output will be displayed in the <b>Console</b> tab.</h4>,
      target: '.dock-box.dock-vbox > .dock-box.dock-hbox > .dock-panel:last-child > .dock.dock-top',
    },
    {
      content: <h4>You can resize and maximize the panels, close unnecessary tabs and drag tabs around. Try it now.</h4>,
      target: '.dock-layout',
      placement: 'top-end',
      spotlightClicks: true,
      hideBackButton: true,
    },
    {
      content: <h4>Once done, you can save the current layout and load the saved layout for the rest of this tutorial. You can also restore to the default layout.</h4>,
      target: '#layout-button',
      spotlightClicks: true,
    },
    {
      content: <h4>With the player controls, you can play and pause the recording, seek to any point of the playback, and control the volume.</h4>,
      target: '.rhap_container',
    },
    {
      content: <h4>You can also perform search functions with this button.</h4>,
      target: '#search-button',
    },
    {
      content: <h4>There are two types of searches - <b>Tutorial</b> and <b>Stackoverflow</b> search.</h4>,
      target: '#classic-modal-slide-title > div',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      content: <h4>In <b>Tutorial search</b>, the search results show where the word appears in the recording. The most frequent words are also provided as search hints.</h4>,
      target: '#simple-tabpanel-0',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      content: <h4>In <b>Stackoverflow search</b>, the search results will show relevant questions from Stack Overflow. Suggested Threads will also appear when there is an error in the <b>Console</b> tab.</h4>,
      target: '#simple-tabpanel-1',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      content: <h4>This brings us to the end of the tour. Thank you.&#x1F60A;</h4>,
      target: 'body',
      placement: 'center',
      placementBeacon: 'top',
    },
  ]

  const setTourStartState = () => {
    // Save the learner's current state and set everything to default state for the tour
    player.current.audio.current.pause();
    setPreTourLayout(layoutRef.current.saveLayout());
    if (playbackIdeRef.current) {
      setPreTourCode(playbackIdeRef.current.editor.getValue());
      playbackIdeRef.current.editor.setValue("");
    }
    if (inputIdeRef.current) {
      setPreTourInput(inputIdeRef.current.editor.getValue());
      inputIdeRef.current.editor.setValue("");
    }
    if (consoleIdeRef.current) {
      setPreTourOutput(consoleIdeRef.current.editor.getValue());
      consoleIdeRef.current.editor.setValue("");
    }
    layoutRef.current.loadLayout(refreshedLayout);
  }

  const handleJoyrideCallback = (data) => {
    // Customize the tour to add code at certain steps and accomodate the modal
    // Step indexes in this function will need to be updated if the steps above change
    const { action, index, type, status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      // Restore to learner's state before tour was started
      setTourStart(false);
      if (playbackIdeRef.current) {
        playbackIdeRef.current.editor.setValue(preTourCode);
        playbackIdeRef.current.editor.clearSelection();
      }
      if (inputIdeRef.current) {
        inputIdeRef.current.editor.setValue(preTourInput);
        inputIdeRef.current.editor.clearSelection();
      }
      if (consoleIdeRef.current) {
        consoleIdeRef.current.editor.setValue(preTourOutput);
        consoleIdeRef.current.editor.clearSelection();
      }
      layoutRef.current.loadLayout(preTourLayout);
      setShowSearchModal(false);
      setStepIndex(0);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      // console.log(action, index, type, status)
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 2 && action === ACTIONS.NEXT) {
        inputIdeRef.current.editor.setValue("python\njava");
        inputIdeRef.current.editor.clearSelection();
      }
      if (index === 3 && action === ACTIONS.NEXT) {
        if (languageChosen == "python") {
          playbackIdeRef.current.editor.setValue("language1 = input()\nlanguage2 = input()\nprint(\"I am learning to code in \" + language1 + \" and \" + language2)");
        } else if (languageChosen == "java") {
          playbackIdeRef.current.editor.setValue("import java.util.Scanner;\n\npublic class HelloWorld {\n\tpublic static void main(String[] args) {\n\t\tScanner input = new Scanner(System.in);\n\t\tString language1 = input.next();\n\t\tString language2 = input.next();\n\t\tSystem.out.print(\"I am learning to code in \" + language1 + \" and \" + language2);\n\t}\n}");
        } else if (languageChosen == "javascript") {
          playbackIdeRef.current.editor.setValue("const stdin = process.openStdin();\n\nstdin.on('data', input => {\n\tinput = input.toString().split(\"\\n\");\n\tlanguage1 = input[0];\n\tlanguage2 = input[1];\n\tconsole.log(\"I am learning to code in \" + language1 + \" and \" + language2);\n});");
        }
        playbackIdeRef.current.editor.clearSelection();
      }
      if (index === 5 && action === ACTIONS.NEXT) {
        consoleIdeRef.current.editor.setValue("> I am learning to code in python and java\n");
        consoleIdeRef.current.editor.clearSelection();
      }
      if (index === 11 && action === ACTIONS.NEXT) {
        setTourStart(false);
        setShowSearchModal(true);
        setTabValue(0);
        setTimeout(() => {
          setTourStart(true);
        }, 400);
      }
      if (index === 12 && action === ACTIONS.PREV) {
        setShowSearchModal(false);
      }
      if (index === 13 && action === ACTIONS.NEXT) {
        setTourStart(false);
        setTabValue(1);
        setTimeout(() => {
          setTourStart(true);
        }, 400);
      }
      if (index === 14 && action === ACTIONS.PREV) {
        setTourStart(false);
        setTabValue(0);
        setTimeout(() => {
          setTourStart(true);
        }, 400);
      }
      if (index === 14 && action === ACTIONS.NEXT) {
        setShowSearchModal(false);
      }
      if (index === 15 && action === ACTIONS.PREV) {
        setTourStart(false);
        setShowSearchModal(true);
        setTabValue(1);
        setTimeout(() => {
          setTourStart(true);
        }, 400);
      }
      setStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    if (id && pid) {
      const requestOptions = {
        method: 'GET',
      };

      fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/tutorial/get/' + id + '/' + pid + '/' + props.userId, requestOptions)
        .then(response => response.json())
        .then(data => {
          setTutorialTitle(data.tutorial_name);
          setFilename(data.tutorial_pages[data.tutorial_page].replace(/[^A-Z0-9]/ig, '_').toLowerCase())
          setPageNumber(data.tutorial_page);
          setPageNames(data.tutorial_pages);
          const tempTutorialType = data.tutorial_type;

          if (tempTutorialType == "Code") {
            setDescription(data.description);
            setLanguageChosen(data.language);
            if (data.language == 'java') {
              setIframePy('java');
            }
            setVersion(data.version);
            setPracticeCode(data.practice_code?JSON.parse(data.practice_code):{})

            setTutorialSectionId(data.tutorial_section_id)

            if (data.frequent_word) {
              const frequentWordData = eval(data.frequent_word).map((word, i) => ({
                key: i, label: word
              }))
              setFrequentWord(frequentWordData);
            }
            if (data.system_ver !== 2) {
              parseUncompressedContent(data);
            }
            else {
              fetchZipFile(data.zipFileURL);
            }

          }

          if (tempTutorialType == "Question") {
            setQuestion(JSON.parse(data.question));
          }

          if (pid > 1) {
            setPrevDisabled(false);
          }
          if (pid < data.tutorial_pages.length) {
            setNextDisabled(false);
          }

          if (data.input_ide) {
            setInputIde(data.input_ide)
          } else {
            setInputIde("")
          }

          setTutorialType(tempTutorialType);

          setRefreshedLayout({
            dockbox: {
              mode: 'horizontal',
              children: [
                {
                  size: 400,
                  tabs: [{ id: 'description-preview' }],
                },
                {
                  mode: 'vertical',
                  size: 600,
                  children: [
                    {
                      size: 600,
                      tabs: [{ id: 'editor' }, { id: 'practice' }]
                    },
                    {
                      mode: 'horizontal',
                      size: 400,
                      children: [
                        {
                          size: 100,
                          tabs: [{ id: 'input' }]
                        },
                        {
                          tabs: [{ id: 'output' }, { id: data.language + '-tutor' }]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          });
        });
    }
  }, [id])

  const fetchZipFile = (zipURL) => {
    fetch(zipURL)       
    .then(function (response) {   
        if (response.status === 200 || response.status === 0) {
            return Promise.resolve(response.blob());
        }
    })
    .then(JSZip.loadAsync)                           
    .then(function (zip) {
      parseZip(zip); 
    })
  }

  const parseZip = (zip) => {
    zip.file("keystroke.json").async("string")
      .then(text => {
        setKeystrokes(JSON.parse(text));
      })

    zip.file("consoleAction.json").async("string")
      .then(text => {
        setConsoleActions(JSON.parse(text));
      })

    zip.file("consoleScrollAction.json").async("string")
      .then(text => {
        setConsoleScrollActions(JSON.parse(text));
      })

    zip.file("inputKeystrokes.json").async("string")
      .then(text => {
        setInputKeystrokes(JSON.parse(text));
      })

    zip.file("inputScrollAction.json").async("string")
      .then(text => {
        setInputScrollActions(JSON.parse(text));
      })

    zip.file("layoutAction.json").async("string")
      .then(text => {
        setLayoutActions(JSON.parse(text));
      })

    zip.file("selectAction.json").async("string")
      .then(text => {
        setSelectActions(JSON.parse(text))
      })

    zip.file("scrollAction.json").async("string")
      .then(text => {
        setScrollActions(JSON.parse(text))
      })

    zip.file("editorScrollAction.json").async("string")
      .then(text => {
        setEditorScrollActions(JSON.parse(text));
      })

    zip.file("description.md").async("string")
      .then(text => {
        setDescription(text);
      })

    zip.file("transcript.json").async("string")
      .then(text => {
        setTranscript(JSON.parse(text));
      })
    
    try {
      zip.file("recording.mp3").async("blob")
      .then(blob => {
        const objectURL = URL.createObjectURL(blob)
        setRecordingBlob(blob);
        setRecordingSrc(objectURL);
      }).catch( err => {
        console.log(error.message)
      })
    } catch (error) {
      
    }

    zip.file("code_content.txt").async("string")
    .then(text => {
      setCodeContent(text);
    })
  }

  const parseUncompressedContent = (data) => {
    setDescription(data.description);
    setKeystrokes(JSON.parse(JSON.parse(data.keystroke)));
    setConsoleActions(JSON.parse(JSON.parse(data.consoleAction)));
    setConsoleScrollActions(JSON.parse(JSON.parse(data.consoleScrollAction)))
    setLayoutActions(JSON.parse(JSON.parse(data.layoutAction)));
    setSelectActions(JSON.parse(JSON.parse(data.selectAction)));
    setScrollActions(JSON.parse(JSON.parse(data.scrollAction)));
    setEditorScrollActions(JSON.parse(JSON.parse(data.editorScrollAction)))
    setTranscript(JSON.parse(JSON.parse(data.transcript)));
    setInputKeystrokes(JSON.parse(JSON.parse(data.inputKeystrokes)))
    setInputScrollActions(JSON.parse(JSON.parse(data.inputScrollAction)))
    if (data.recording != null) {
      setRecordingSrc(data.recording);
    } else {
      const duration = Math.ceil(data.duration / 1000);
      setRecordingSrc(createSilentAudio(duration ? duration : 1));
    }
  }

  useEffect(() => {
    const details = {
      'userid': props.userId,
      'tutorialid': id,
      'role': props.role,
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/get_learner_layout', {
      method: 'POST',
      body: JSON.stringify(details)
    })
      .then(response => response.json())
      .then(data => { setLearnerLayout(JSON.parse(data.layout)) })
  }, []);

  const savePracticeCode = () => {
    // setBackdropOpen(true)

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ tutorial_section_id: tutorialSectionId, code_input: practiceCode, userid: props.userId })
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/save_user_state', requestOptions)
      .then(response => {
        // setBackdropOpen(false)
        setIdeChanged(false)
        if (response.status === 200) {
          setChipText('Saved');
          setChipSuccess(true);
        } else {
          setChipText('Error');
          setChipSuccess(false);
        }
        setChipVisible(true);
  
        setTimeout(()=>{
          setChipVisible(false);
        },3000)
      })
  }

  useBeforeunload((event) => {
    if(ideChanged || transcriptChanged){
      event.preventDefault()
    }
  })

  const exportPracticeCode = () => {
    const zip = new JSZip();
    var fileExtension;
    if(languageChosen == "python"){
      fileExtension =".py"
    }else if(languageChosen == "java"){
      fileExtension = ".java"
    }else{
      fileExtension = ".javascript"
    }
    for (const filename in practiceCode) {
      zip.file(tutorialTitle.replaceAll(" ", "_")+"_" +filename+fileExtension, practiceCode[filename])
    }


    // This will create a folder called folder1 in the zip file 
    // zip.file("folder1/test.txt", "hello")


    zip.generateAsync({type:"blob"})
    .then(function (blob) {
        saveAs(blob, tutorialTitle+".zip");
    });


  }



  return (
    <div>
      {tutorialType == "Code" &&
        <Joyride
          callback={handleJoyrideCallback}
          steps={steps}
          stepIndex={stepIndex}
          run={tourStart}
          continuous={true}
          disableCloseOnEsc={true}
          showSkipButton={true}
          disableOverlayClose={true}
          locale={{ back: 'Back', close: 'Close', last: 'Next', next: 'Next', skip: 'Skip' }}
          styles={
            {
              buttonClose: {
                display: 'none',
              },
            }
          }
        />
      }
      <Grid container spacing={0} className={classes.gridContainer}>
        {/* Navigation Bar */}
        <TutorialHeader
          userId={props.userId}
          role={props.role}
          id={id}
          pid={pid}
          layoutRef={layoutRef}
          tutorialTitle={tutorialTitle}
          setTourStart={setTourStart}
          setTourStartState={setTourStartState}
          setShowSearchModal={setShowSearchModal}
          pageNumber={pageNumber}
          pageNames={pageNames}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          tutorialType={tutorialType}
          learnerLayout={learnerLayout}
          setLearnerLayout={setLearnerLayout}
          refreshedLayout={refreshedLayout}
          showCaption={showCaption}
          setShowCaption={setShowCaption}
          playbackBarAlwaysVisible={playbackBarAlwaysVisible}
          setPlaybackBarAlwaysVisible={setPlaybackBarAlwaysVisible}
          setShowTranscriptEditor={setShowTranscriptEditor}
          savePracticeCode={savePracticeCode}
          exportPracticeCode={exportPracticeCode}
          chipText={chipText}
          setChipText={setChipText}
          chipSuccess={chipSuccess}
          setChipSuccess={setChipSuccess}
          chipVisible={chipVisible}
          setChipVisible={setChipVisible}
        />

        {tutorialType == "Code" &&
          <CodeSection
            userId={props.userId}
            role={props.role}
            tutorialTitle={tutorialTitle}
            showTranscriptEditor={showTranscriptEditor}
            setShowTranscriptEditor={setShowTranscriptEditor}
            practiceCode={practiceCode}
            description={description}
            transcript={transcript}
            setTranscript={setTranscript}
            layoutRef={layoutRef}
            inputIdeRef={inputIdeRef}
            playbackIdeRef={playbackIdeRef}
            consoleIdeRef={consoleIdeRef}
            practiceIdeRef={practiceIdeRef}
            player={player}
            filename={filename}
            languageChosen={languageChosen}
            iframePy={iframePy}
            version={version}
            themeChosen={themeChosen}
            keystrokes={keystrokes}
            consoleActions={consoleActions}
            consoleScrollActions={consoleScrollActions}
            inputKeystrokes={inputKeystrokes}
            inputScrollActions={inputScrollActions}
            layoutActions={layoutActions}
            selectActions={selectActions}
            scrollActions={scrollActions}
            editorScrollActions={editorScrollActions}
            recordingSrc={recordingSrc}
            recordingBlob={recordingBlob}
            showSearchModal={showSearchModal}
            setShowSearchModal={setShowSearchModal}
            frequentWord={frequentWord}
            tutorialSectionId={tutorialSectionId}
            input={inputIde}
            tabValue={tabValue}
            setTabValue={setTabValue}
            showCaption={showCaption}
            setShowCaption={setShowCaption}
            playbackBarAlwaysVisible={playbackBarAlwaysVisible}
            savePracticeCode={savePracticeCode}
            backdropOpen={backdropOpen}
            setBackdropOpen={setBackdropOpen}
            setIdeChanged={setIdeChanged}
            setPracticeCode={setPracticeCode}
            setTranscriptChanged={setTranscriptChanged}
          />
        }

        {question &&
          <QuestionSection question={question} />
        }

      </Grid>
    </div>
  );
}
