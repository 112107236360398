import React, { useState } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import SideBar from "../SideBar/SideBar.js";
import Button from "../CustomButtons/Button.js";
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
// material ui icon
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import SaveIcon from '@material-ui/icons/Save';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit'
import Check from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styles from "assets/jss/material-kit-react/views/TutorialPage.js";
import { ListItem, MenuList } from '@material-ui/core';

const useStyles = makeStyles(styles);


export default function TutorialHeader({
  userId,
  role,
  id,
  pid,
  layoutRef,
  tutorialTitle,
  setTourStartState,
  setTourStart,
  setShowSearchModal,
  pageNumber,
  pageNames,
  tutorialType,
  learnerLayout,
  setLearnerLayout,
  refreshedLayout,
  showCaption,
  setShowCaption,
  playbackBarAlwaysVisible,
  setPlaybackBarAlwaysVisible,
  setShowTranscriptEditor,
  savePracticeCode,
  exportPracticeCode,
  chipSuccess,
  setChipSuccess,
  chipText,
  setChipText,
  chipVisible,
  setChipVisible
}) {
  const classes = useStyles();
  const [helpAnchor, setHelpAnchor] = useState(null);
  const [layoutAnchor, setLayoutAnchor] = useState(null);
  const [tutorialAnchor, setTutorialAnchor] = useState(null);
  const [currentLayout, setCurrentLayout] = useState({
    "description-preview": true,
    caption: true,
    editor: true,
    practice: true,
    input: true,
    output: true,
    "python-tutor": true,
  });

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const saveLayout = () => {    
    var newLayout = layoutRef.current.saveLayout();
    setLearnerLayout(newLayout);

    const saveDetails = {
      'userid': userId,
      'tutorialid': id,
      'layout': newLayout,
      'role': role,
    };

    fetch(process.env.REACT_APP_TUTORIAL_URL + "/" + localStorage.getItem("session_id") + '/save_learner_layout', {
      method: 'POST',
      body: JSON.stringify(saveDetails)
    }).then(res => {
      if (res.status === 200) {
        setChipText('Saved');
        setChipSuccess(true);
      } else {
        setChipText('Error');
        setChipSuccess(false);
      }
      setChipVisible(true);

      setTimeout(() => {
        setChipVisible(false);
      }, 3000)
    })
  }

  return (
    <Grid container className={classes.gridHeader} justify="space-between" direction="row" alignItems="center">
      <Grid item >

        <Grid container alignItems='center'>
          <SideBar id={id} pid={pid} />
          <Hidden mdDown>
            <h5 className={classes.textOverflow}>{tutorialTitle} </h5>
            <ArrowForwardIosIcon style={{ "fontSize": 12, margin: "2px 8px 0 12px" }} color="disabled" />
          </Hidden>
          <Button
            className={isSmallScreen && classes.menuButtonDense}
            size="sm"
            link
            onClick={(e) => setTutorialAnchor(e.currentTarget)}
            endIcon={<KeyboardArrowDownIcon />}

          >
            <h5 className={classes.textOverflowTitle}>{pageNames[pageNumber]}</h5>

          </Button>
          <Menu
            anchorEl={tutorialAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(tutorialAnchor)}
            onClose={() => setTutorialAnchor(null)}
            TransitionComponent={Fade}
          >
            {pageNames.map((name, i )=>
            <MenuList dense key={i}>
              <MenuItem disabled={i === pageNumber} onClick={() => window.location.href = "/tutorial/" + id + "/" + (i+1)}>
                <ListItemText>{name}</ListItemText>
              </MenuItem>
            </MenuList>

            )}
          </Menu>
        </Grid>
      </Grid>
      <Grid item className={classes.gridHeaderRight}>
        {chipVisible &&
          <Chip label={chipText} className={[classes.statusIndicator, chipSuccess ? classes.successIndicator : classes.errorIndicator]} />
        }

        <div className={classes.topRightIcon}>
          {role === "author" && tutorialType === "Code" &&
            <Tooltip title="Edit Transcript">
              <Button id="edit-transcript" size="sm" onClick={() => { setShowTranscriptEditor(true) }} justIcon={isSmallScreen}>
                <EditIcon />
                <Hidden mdDown>
                  <span style={{ paddingTop: "2px" }}>
                    Edit Transcript
                  </span>
                </Hidden>
              </Button>
            </Tooltip>
          }
          {tutorialType === "Code" &&
            <>
              <Tooltip title="Save Practice">
                <Button id="save-practice" size="sm" onClick={() => savePracticeCode()} justIcon={isSmallScreen}>
                  <SaveIcon />
                  <Hidden mdDown>
                    <span style={{ paddingTop: "2px" }}>Save</span>
                  </Hidden>
                </Button>
              </Tooltip>
              <Tooltip title="Export Practice">
                <Button id="export-practice" size="sm" onClick={() => exportPracticeCode()} justIcon={isSmallScreen}>
                  <CloudDownloadIcon />
                  <Hidden mdDown>
                    <span style={{ paddingTop: "2px" }}>Export</span>
                  </Hidden>
                </Button>
              </Tooltip>
            </>
          }
          {role !== "author" && tutorialType === "Code" &&
            <Tooltip title="Search">
              <Button id="search-button" size="sm" onClick={() => setShowSearchModal(true)} justIcon={isSmallScreen}>
                <SearchIcon />
                <Hidden mdDown>
                  Search
                </Hidden>
              </Button>
            </Tooltip>
          }
          {tutorialType === "Code" &&
            <div>
              <Button
                className={isSmallScreen && classes.menuButtonDense}
                size="sm"
                link
                onClick={(e) => setLayoutAnchor(e.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}

              >
                Layout
              </Button>

              <Menu
                anchorEl={layoutAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(layoutAnchor)}
                onClose={() => setLayoutAnchor(null)}
                TransitionComponent={Fade}
              >
                <MenuList dense>
                  <MenuItem onClick={() => saveLayout()}>
                    <ListItemIcon>
                      <SaveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Save Layout</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => layoutRef.current.loadLayout(learnerLayout)}>
                    <ListItemIcon>
                      <ViewCompactIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Load Saved Layout</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => layoutRef.current.loadLayout(refreshedLayout)}>
                    <ListItemIcon>
                      <RefreshIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Restore Layout</ListItemText>
                  </MenuItem>
                  <Divider />
                  <ListItem>
                    <Typography variant="subtitle2">Caption</Typography>
                  </ListItem>
                  <MenuItem onClick={() => setShowCaption(!playbackBarAlwaysVisible)}>
                    {showCaption &&
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                    }
                    <ListItemText inset={!showCaption}>Always shown</ListItemText>
                  </MenuItem>
                  <Divider />
                  <ListItem>
                    <Typography variant="subtitle2">Playback Bar</Typography>
                  </ListItem>
                  <MenuItem onClick={() => setPlaybackBarAlwaysVisible(!playbackBarAlwaysVisible)}>
                    {playbackBarAlwaysVisible &&
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                    }
                    <ListItemText inset={!playbackBarAlwaysVisible}>Always shown</ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>

              <Button
                className={isSmallScreen && classes.menuButtonDense}
                size="sm"
                link
                onClick={(e) => setHelpAnchor(e.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Help
              </Button>
              <Menu
                anchorEl={helpAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(helpAnchor)}
                onClose={() => setHelpAnchor(null)}
                TransitionComponent={Fade}
              >
                <MenuList dense>
                  <MenuItem
                    onClick={() => {
                      setHelpAnchor(null)
                      setTourStartState();
                      setTourStart(true);
                    }}>
                    Play Onboarding Tutorial
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          }
        </div>
      </Grid>
    </Grid>
  );
}
