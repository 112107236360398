import React, {useState, useEffect, useRef} from 'react';
import {useTable} from 'react-table';
import { Grid } from '@material-ui/core';

export default function EditableTable({ columns, data, updateData, removeRow, addRow })  {
	const convertToTime = (miliseconds) => {
		const hours = Math.floor(miliseconds / 3600 / 1000);
		miliseconds -= hours * 3600 * 1000;
		const minutes = Math.floor(miliseconds / 60 / 1000);
		miliseconds -= minutes * 60 * 1000;
		const seconds = Math.floor(miliseconds/1000);
		miliseconds -= seconds * 1000
		// return `${hours < 10 ? 0:''}${hours}:${minutes < 10 ? 0:''}${minutes}:${seconds < 10 ? 0:''}${seconds}:${miliseconds < 100 ? 0:''}${miliseconds < 10 ? 0: ''}${miliseconds}`;
		if(miliseconds < 100){
			miliseconds = "0" + miliseconds
		}

		if (miliseconds < 10){
			miliseconds = "0" + miliseconds
		}
		
		return [
			hours < 10 ? "0"+hours: hours, 
			minutes < 10 ? "0"+minutes: minutes, 
			seconds < 10 ? "0"+seconds: seconds, 
			miliseconds];
	}

	const convertToMs = (value_arr) => {
		return value_arr[0] * 3600 * 1000 + value_arr[1] * 60 *1000 + value_arr[2] * 1000 + parseInt(value_arr[3])
		// return (value_arr[0]*3600 + value_arr[1] * 60 + value_arr[2])* 1000 + value_arr[3]
	}

	// Create an editable cell renderer
	const EditableCell = ({
		value: initialValue,
		row: { index },
		column: { id },
		updateData, // This is a custom function that we supplied to our table instance
	}) => {
		// We need to keep and update the state of the cell normally
		const [value, setValue] = useState([])
		const [hourInput, setHourInput] = useState(false)
		const [minInput, setMinInput] = useState(false)
		const [secInput, setSecInput] = useState(false)
		const [msInput, setMsInput] = useState(false)
		const [errorMsg, setErrorMsg] = useState(false)
		const focusInput = useRef(null);

		const onChange = (e, valIndex) => {
			if(
				(e.target.value < 0) ||
				(valIndex == 3 && e.target.value >= 1000) || 
				(valIndex != 3 && e.target.value >= 60)
			){
				return
			}

			let toUpdate = e.target.value
			if(toUpdate != ''){
				toUpdate = parseInt(toUpdate)
			}

			const updatedValues = [...value]
			updatedValues[valIndex] = toUpdate
			setValue(updatedValues)
		}

		// We'll only update the external data when the input is blurred
		const onBlur = (valIndex) => {
			for(let i = 0 ; i < value.length; i++){
				if(value[i] == ''){
					value[i] = i < 3 ? '00': '000' // if i is index 3 (ms) use 3 zeroes
				}
			}

			if(!updateData(index, id, convertToMs(value))){
				setErrorMsg(true)
				if(!(initialValue instanceof Array)){
					initialValue = convertToTime(initialValue)
				}
				setValue(initialValue)
			}else{
				setErrorMsg(false)
			}
		}

		useEffect(() => {
			initialValue = convertToTime(initialValue)
			setValue(initialValue)
		}, [])

		useEffect(() => {
			if(hourInput || minInput || secInput || msInput){
				focusInput.current.focus()
			}
		}, [hourInput, minInput, secInput, msInput])

		// If the initialValue is changed external, sync it up with our state
		useEffect(() => {
			if(initialValue instanceof Array){
				return
			}

			initialValue = convertToTime(initialValue)
			setValue(initialValue)
		}, [initialValue])

		return (
			<div>
				{errorMsg &&
					<span style={{color: 'red'}}>Improper time</span>
				}
				<div>
					{hourInput 
						?<input
							min="0"
							max="59"
							type="number"
							maxLength="2"
							value={value[0]}
							onChange={e => onChange(e, 0)}
							ref={focusInput}
							onBlur={() => {
								setHourInput(false)
								onBlur(0)
							}}/>
						:<span onClick={()=>{setHourInput(true)}}>{value[0]}</span>
					}:
					{minInput 
						?<input
							min="0"
							max="59"
							type="number"
							maxLength="2"
							value={value[1]}
							onChange={e => onChange(e, 1)}
							ref={focusInput}
							onBlur={() => {
								setMinInput(false)
								onBlur(1)
							}}/>
						:<span onClick={()=>{setMinInput(true)}}>{value[1]}</span>
					}:
					{secInput 
						?<input
							min="0"
							max="59"
							type="number"
							maxLength="2"
							value={value[2]}
							onChange={e => onChange(e, 2)}
							ref={focusInput}
							onBlur={() => {
								setSecInput(false)
								onBlur(2)
							}}/>
						:<span onClick={()=>{setSecInput(true)}}>{value[2]}</span>
					}:
					{msInput 
						?<input
							min="0"
							max="999"
							type="number"
							maxLength="3"
							value={value[3]}
							onChange={e => onChange(e, 3)}
							ref={focusInput}
							onBlur={() => {
								setMsInput(false)
								onBlur(3)
							}}/>
						:<span onClick={()=>{setMsInput(true)}}>{value[3]}</span>
					}
				</div>
			</div>
		);
	}

	const EditableTextArea = ({
		value: initialValue,
		row: { index },
		column: { id },
		updateData,
		removeRow,
		addRow,
	}) => {
		const [value, setValue] = useState(initialValue)
		const [textInput, setTextInput] = useState(false)
		const [isButtonShown, setIsButtonShown] = useState(false)
		const focusInput = useRef(null);

		const onChange = e => {
			setValue(e.target.value)
		}

		const onBlur = () => {
			setTextInput(false)
			updateData(index, id, value)
		}

		useEffect(() => {
			if(textInput){
				focusInput.current.focus()
			}
		}, [textInput])


		useEffect(() => {
			setValue(initialValue)
		}, [initialValue])

		return (
			<Grid container onMouseEnter={()=> setIsButtonShown(true)}
				onMouseLeave={()=> setIsButtonShown(false)}
				direction="row"
			>
				<Grid item 
					xs={10}
					sm={10}
					md={10}
					lg={10}
					onClick={()=>{setTextInput(true)}}
					>
					{textInput
						? <textarea onChange={onChange} onBlur={onBlur} rows="3" cols="30" value={value} ref={focusInput}/>
						: <span>{value == "" ? <i>Click to enter text</i>:value}</span>
					}
				</Grid>
				<Grid item xs={2} sm={2} md={2} lg={2}>
					{isButtonShown &&
						<Grid container direction="row" justify='flex-end'>
							<Grid container justify='flex-end'>
								<button onClick={()=>{addRow(index)}} style={{width:"25px"}}>+</button>
							</Grid>
							<Grid container justify='flex-end'>
								<button onClick={() => {removeRow(index)}} style={{width:"25px"}}>-</button>
							</Grid>
						</Grid>
					}
				</Grid>

			</Grid>
			
		);
		
	}

	// Set our editable cell renderer as the default Cell renderer
	const defaultColumn = {
		Cell: EditableCell,
		TextArea: EditableTextArea,
	}

	const {
		getTableProps, 
		getTableBodyProps, 
		headerGroups,
		prepareRow,
		rows} = useTable({
			columns,
			data,
			defaultColumn,
			updateData,
			removeRow,
			addRow,
		})

	return (
		<>
			<table {...getTableProps()}>
				<thead>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map(column => (
						<th {...column.getHeaderProps()}>{column.render('Header')}</th>
					))}
					</tr>
				))}
				</thead>
				<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row)
					return (
					<tr {...row.getRowProps()}>
						{row.cells.map(cell => {
							if(cell.column.Header === 'Transcript'){
								return <td {...cell.getCellProps()}>{cell.render('TextArea')}</td>
							}
							return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
						})}
					</tr>
					)
				})}
				</tbody>
			</table>
		</>
	)	
}