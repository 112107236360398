import React, { useState, useEffect} from 'react';
import EditableTable from 'components/EditableTable.js';
import Button from "components/CustomButtons/Button.js";

export default function TranscriptEditor(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Start Time',
        accessor: 'timestamp' // this is the key in the data
      },
      {
        Header: 'End Time',
        accessor: 'endTime'
      },
      {
        Header: 'Transcript',
        accessor: 'text',
      },
    ],
    []
  )

  const [data, setData] = useState(props.transcript);
  const [updateComplete, setUpdateComplete] = useState(props.updateComplete);
  const [saveDisabled, setSaveDisabled] = useState(props.saveDisabled);
  const validateTime = (rowIndex, columnId, value) => {
    if(columnId == "timestamp"){
      // check if new startTime is before endTime of the same row
      if(value > data[rowIndex]["endTime"]){
        return false
      }

      // check if new startTime is after endTime of prev row
      if(rowIndex != 0 && value < data[rowIndex-1]["endTime"]){
        return false
      }
    }

    if(columnId == "endTime"){
      // check if new endTime is after startTime of the same row
      if(value < data[rowIndex]["timestamp"]){
        return false
      }

      // check if new endTime is before startTime of next row
      if(rowIndex != data.length-1 && value > data[rowIndex+1]["timestamp"]){
        return false
      }
    }

    return true
  }

	// We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateData = (rowIndex, columnId, value) => {
    if(validateTime(rowIndex, columnId, value)){
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
      return true
    }

    return false
  }

  const addRow = (rowIndex) => {
    const updatedData = [...data]

    const toAdd = {
      text: '',
      timestamp: updatedData[rowIndex]["endTime"] + 1,
      endTime: updatedData[rowIndex]["endTime"] + 2
    }

    updatedData.splice(rowIndex+1,0,toAdd)

    let currentRow = rowIndex + 2
    
    while(currentRow < updatedData.length){
      // if currentRow startTime is <= to prevRow endTime,set it as prevRow endTime + 1
      if(updatedData[currentRow]["timestamp"] <= updatedData[currentRow-1]['endTime']){
        updatedData[currentRow]['timestamp'] = updatedData[currentRow-1]['endTime']+1
      }

      // if currentRow startTime is >= currentRow endTime set it as currentRow startTime + 1
      if(updatedData[currentRow]['timestamp'] >= updatedData[currentRow]['endTime']){
        updatedData[currentRow]['endTime'] = updatedData[currentRow]['timestamp']+1 
      }
      
      if(currentRow+1 != updatedData.length && !(updatedData[currentRow]['timestamp'] >= updatedData[currentRow +1]['timestamp']) && !(updatedData[currentRow]['endTime'] >= updatedData[currentRow+1]['timestamp'])){
        break
      }

      currentRow++
    }
    setData(updatedData)
  }

  const removeRow = (rowIndex) => {
    const updatedData = [...data]
    // console.log(updatedData)
    updatedData.splice(rowIndex, 1)
    setData(updatedData)
  }

  useEffect(() => {
    // on data update call the function passed into to update the transcript data in parent
    props.updateTranscript(data);
    if(data != props.transcript){
      setSaveDisabled(false);
      setUpdateComplete(false);
    }
  }, [data])

  useEffect(() => {
    props.setUpdateComplete(updateComplete)
  },[updateComplete])

  useEffect(() => {
    props.setSaveDisabled(saveDisabled)
  }, [saveDisabled])



  const tableStyles = `
  table {
    border-spacing: 0;
    border: 1px solid black;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  th, td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    height: 60px;
    word-wrap: break-word;
  }
  th:last-child {
    border-right: 0;
    min-width:300px;
  }
  td:last-child{
    border-right:0;
    min-width:300px;
  }
  td input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
  }
  .transcriptEditor{
    overflow: auto;
    height: 100%;
    padding: 15px;
  }
  `
  return (
    <div className='transcriptEditor'>
      <style>
        {tableStyles}
      </style>
      <EditableTable
        columns={columns}
        data={data}
        updateData={updateData}
        removeRow={removeRow}
        addRow={addRow}
      />
      <Button id="save-transcript" onClick={() => {
        props.handleUpdateTranscript(data)
        .then(()=>{
          setUpdateComplete(true);
          setSaveDisabled(true);
        })
        }}
        disabled={saveDisabled}
        >
          Save Transcript to S3
      </Button>
      <Button id="close-transcript" onClick={() => {props.setShowTranscriptEditor(false)}}>
        Close Editor
      </Button>
      {updateComplete &&
        <div>
          Transcript Has Been Updated
        </div>
      }

    </div>
  )
}